var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"va-order-header-grid pr-2 sm:px-0"},[(_vm.officeCurrent)?_c('div',{staticClass:"va-order-header-grid-item w-full"},[_c('label',{staticClass:"va-input--label"},[_vm._v("Филиал")]),_c('alt-select',{attrs:{"options":_vm.offices,"track-by":"_id","value":_vm.officeCurrent},on:{"input":_vm.chageOffice},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var office = ref.option;
return [_c('span',[_vm._v(_vm._s(office.info.name))]),_c('br'),(office.info.description && office.info.description.length > 0)?_c('small',{staticClass:"text-xs opacity-75 truncate"},[_vm._v(" "+_vm._s(office.info.description)+" ")]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var office = ref.option;
return [_c('feather-icon',{staticClass:"w-1 mr-0.5",attrs:{"icon":"HomeIcon"}}),_vm._v(" "+_vm._s(office && office.info ? office.info.name : "")+" ")]}}],null,false,425853106)})],1):_vm._e(),(_vm.orderType)?_c('div',{staticClass:"va-order-header-grid-item w-full"},[_c('label',{staticClass:"va-input--label"},[_vm._v("Тип заявки")]),_c('alt-select',{attrs:{"options":_vm.orderTypes,"track-by":"_id","value":_vm.orderType},on:{"input":_vm.changeType},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var type = ref.option;
return [_c('span',[_vm._v(_vm._s(type.name))]),_c('br'),(type.description && type.description.length > 0)?_c('small',{staticClass:"text-xs opacity-75 truncate"},[_vm._v(" "+_vm._s(type.description)+" ")]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var type = ref.option;
return [_c('feather-icon',{staticClass:"w-1 mr-0.5",attrs:{"icon":"CompassIcon"}}),_vm._v(" "+_vm._s(type ? type.name : "")+" ")]}}],null,false,3278587789)})],1):_vm._e(),(_vm.orderStage)?_c('div',{staticClass:"va-order-header-grid-item w-full"},[_c('label',{staticClass:"va-input--label"},[_vm._v("Этап")]),_c('alt-select',{attrs:{"options":_vm.orderStages,"track-by":"_id","value":_vm.orderStage},on:{"input":_vm.changeStage},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var stage = ref.option;
return [_c('span',[_vm._v(_vm._s(stage.name))]),_c('br'),(stage.description && stage.description.length > 0)?_c('small',{staticClass:"text-xs opacity-75 truncate"},[_vm._v(" "+_vm._s(stage.description)+" ")]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var stage = ref.option;
return [_c('feather-icon',{staticClass:"w-1 mr-0.5",attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" "+_vm._s(stage ? stage.name : "")+" ")]}}],null,false,2128144009)})],1):_vm._e(),(_vm.employees)?_c('div',{staticClass:"va-order-header-grid-item w-full"},[_c('label',{staticClass:"va-input--label"},[_vm._v("Ответственный")]),_c('alt-select',{attrs:{"options":_vm.employees,"track-by":"_id","value":_vm.manager},on:{"input":_vm.changeManager},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var employee = ref.option;
return [_c('span',[_vm._v(_vm._s(employee.userRef.info.name))]),_c('br'),(employee.position && employee.position.length > 0)?_c('small',{staticClass:"text-xs opacity-75 truncate"},[_vm._v(" "+_vm._s(employee.position)+" ")]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var employee = ref.option;
return [_c('feather-icon',{staticClass:"w-1 mr-0.5",attrs:{"icon":"UserIcon"}}),_vm._v(" "+_vm._s(employee && employee.userRef ? employee.userRef.info.name : "")+" ")]}}],null,false,2624456607)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }