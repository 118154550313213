import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BDropdown, BDropdownForm, BFormCheckbox, BFormCheckboxGroup } from "bootstrap-vue";

type FieldReceiver<TValue = any, TResult = string> = (item: TValue) => TResult;

@Component({
    name: "alt-checkbox-select",
    components: {
        BDropdown,
        BDropdownForm,
        BFormCheckbox,
        BFormCheckboxGroup,
    },
})
export default class AltCheckboxSelect extends Vue {
    @Prop({ type: Array, required: true })
    private items!: any[];

    // выбранные элементы
    @Prop({ type: Array, required: true })
    private value!: any[];

    @Prop({ type: Function, required: true })
    private getKey!: FieldReceiver;

    @Prop({ type: Function, required: true })
    private getText!: FieldReceiver;

    @Prop({ type: String, required: true })
    private text!: string;

    @Prop({ type: String, default: "primary" })
    private variant!: string;

    private selected: any[] = [];

    @Watch("value", { deep: true, immediate: true })
    private onValueChanged(): void {
        this.selected = this.value;
    }

    private change(): void {
        this.$emit("input", this.selected);
    }
}
