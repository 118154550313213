import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

@Component({
    name: "alt-select",
    components: { Multiselect },
})
export default class AltSelect extends Vue {
    @Prop({ type: String, default: "" })
    private id!: string;

    @Prop({ type: String, default: "" })
    private placeholder!: string;

    @Prop({ type: String, default: "" })
    private trackBy!: string;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    @Prop({ type: Array, default: () => [] })
    private options!: any[];

    @Prop()
    private value!: any;

    private currentValue: any = null;

    @Watch("value")
    private onValueChanged(value: any): void {
        this.currentValue = value;
    }

    public mounted(): void {
        this.currentValue = this.value;
    }

    private onInput(value: any): void {
        this.$emit("input", value);
    }

    private onOpen(id: any): void {
        this.$emit("open", id);
    }
}
