import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { IClientSource, IOffice, IOrderType, PermissionCommonSection, PermissionRight } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import AltIcon from "@/core/components/alt-icon";
import { OrderBlockClientHandler } from "./order-block-client-handler";
import router from "@/router/router";
import { AppException } from "@/core/exceptions";

@Component({
    components: { BButton, ControlComponent, AltIcon },
})
export default class OrderCreateClient extends Vue {
    @Prop({ required: true })
    private handler!: OrderBlockClientHandler;

    @Prop({ default: () => null })
    private office!: IOffice | null;

    @Prop({ default: () => null })
    private orderType!: IOrderType | null;

    @Prop({ default: () => [] })
    private clientSources!: IClientSource[];

    private get can(): any {
        const secure = this.$secure;
        return {
            get clientsRead(): boolean {
                return secure.checkCommon(PermissionCommonSection.Clients, PermissionRight.Read);
            },
        };
    }

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    @Watch("office", { immediate: true, deep: true })
    private onOfficeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        this.handler.init(this.office, this.orderType, this.clientSources);
    }

    public deselectClient(): void {
        this.handler.setClient(null);
    }

    private async applyOpenClientLink(): Promise<void> {
        try {
            if (!this.handler.clientExisted) {
                throw new AppException("Клиент не задан.");
            }

            const routeData = router.resolve({ name: "clients", query: { id: this.handler.clientExisted.id } });
            window.open(routeData.href, "_blank");
        } catch {
            this.$alt.toast.error("Не удалось открыть ссылку на клиента.");
        }
    }
}
