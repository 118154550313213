import { ISettingsTableUpdateDto, ITableColumn, TableView } from "@lib";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import { Button, ButtonGroup, Control } from "@core/components/alt-ui/controls";
import { TableApi } from "@/core/components/alt-table";
import { CardListApi, ICardWidget } from "@/core/components/alt-card-list";
import { IAltTableToolboxExport, AltTableToolboxControl } from "@/core/components/alt-table/alt-table-toolbox-control";
import { AltCardListToolboxControl } from "@/core/components/alt-card-list/alt-card-list-toolbox-control";

export interface IAltUniversalTableToolboxContext {
    mobile: boolean;
    view: TableView;
    //
    columns: ITableColumn[];
    export?: IAltTableToolboxExport;
    tableApi: TableApi;
    //
    header?: ICardWidget;
    footer?: ICardWidget;
    widgets?: ICardWidget[];
    widgetsAll: ICardWidget[];
    cardsApi: CardListApi;
    //
    changeTableView: (view: TableView) => void;
    saveSettings: (dto: ISettingsTableUpdateDto) => void;
}

export class AltUniversalTableToolbox extends Sidebar<IAltUniversalTableToolboxContext, boolean> {
    private tableControl!: AltTableToolboxControl;
    private cardControl!: AltCardListToolboxControl;

    //

    private btnViewTable!: Button;
    private btnViewCards!: Button;
    private btnViewGroup!: ButtonGroup;

    private btnSave!: Button;
    private btnCancel!: Button;

    private selectedTableView!: TableView;
    private context?: IAltUniversalTableToolboxContext;

    public constructor() {
        super("alt-universal-table-toolbox");
        this.initializeControls();
    }

    public show(context: IAltUniversalTableToolboxContext): Promise<boolean> {
        this.context = context;
        this.selectedTableView = context.view;
        this.title = context.view === TableView.Table ? "НАСТРОЙКИ ТАБЛИЦЫ" : "НАСТРОЙКИ КАРТОЧЕК";

        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.btnViewTable = new Button();
        this.btnViewTable.id = "alt-universal-table-toolbox.button-table";
        this.btnViewTable.text = "Таблица";
        this.btnViewTable.style = "whitespace-nowrap";
        this.btnViewTable.variant = this.selectedTableView === TableView.Table ? "primary" : "outline-primary";
        this.btnViewTable.addClickHandler(() => this.selectView(TableView.Table));

        this.btnViewCards = new Button();
        this.btnViewCards.id = "alt-universal-table-toolbox.button-card-list";
        this.btnViewCards.text = "Карточки";
        this.btnViewCards.style = "whitespace-nowrap";
        this.btnViewCards.variant = this.selectedTableView === TableView.Cards ? "primary" : "outline-primary";
        this.btnViewCards.addClickHandler(() => this.selectView(TableView.Cards));

        this.btnViewGroup = new ButtonGroup();
        this.btnViewGroup.id = "alt-universal-table-toolbox.group-views";
        this.btnViewGroup.class = "w-100 mb-2";
        this.btnViewGroup.addButtons([this.btnViewTable, this.btnViewCards]);

        //

        this.tableControl = new AltTableToolboxControl();

        if (this.context) {
            this.tableControl.initialize({
                columns: this.context.columns,
                export: this.context.export,
                tableApi: this.context.tableApi,
            });
        }

        //

        this.cardControl = new AltCardListToolboxControl();

        if (this.context) {
            this.cardControl.initialize({
                header: this.context.header,
                footer: this.context.footer,
                widgets: this.context.widgets,
                widgetsAll: this.context.widgetsAll,
                api: this.context.cardsApi,
            });
        }

        //

        this.btnSave = new Button();
        this.btnSave.id = "alt-universal-table-toolbox.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.class = "mr-0.75";
        this.btnSave.variant = "success";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.btnCancel = new Button();
        this.btnCancel.id = "alt-universal-table-toolbox.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.variant = "flat-danger";
        this.btnCancel.addClickHandler(() => this.hide());
    }

    public get controls(): Control[] {
        if (this.selectedTableView === TableView.Table) {
            return [this.btnViewGroup, this.tableControl];
        } else if (this.selectedTableView === TableView.Cards) {
            return [this.btnViewGroup, this.cardControl];
        }

        return [];
    }

    public get footerControls(): Control[] {
        return [this.btnSave, this.btnCancel];
    }

    private selectView(view: TableView): void {
        if (!this.context) {
            return;
        }

        if (view === TableView.Table) {
            this.title = "НАСТРОЙКИ ТАБЛИЦЫ";
            this.btnViewTable.variant = "primary";
            this.btnViewCards.variant = "outline-primary";
        } else if (view === TableView.Cards) {
            this.title = "НАСТРОЙКИ КАРТОЧЕК";
            this.btnViewCards.variant = "primary";
            this.btnViewTable.variant = "outline-primary";
        }

        this.selectedTableView = view;
        this.context.changeTableView(view);
    }

    // Нужно для обработки сворачивания
    public hide(): void {
        this.reset();
        super.hide();
    }

    private reset(): void {
        if (this.selectedTableView === TableView.Table) {
            this.tableControl.reset();
        } else if (this.selectedTableView === TableView.Cards) {
            this.cardControl.reset();
        }

        if (this.context) {
            this.selectView(this.context.view);
        }
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const dto: ISettingsTableUpdateDto = {
            view: {
                desktop: this.context?.mobile ? undefined : this.selectedTableView,
                mobile: this.context?.mobile ? this.selectedTableView : undefined,
            },
        };

        if (this.selectedTableView === TableView.Table) {
            const settings = this.tableControl.getSettings();
            dto.columns = settings.columns;
        } else if (this.selectedTableView === TableView.Cards) {
            const settings = this.cardControl.getSettings();
            dto.cards = settings;
        }

        this.context?.saveSettings(dto);
        super.hide();
    }
}
