import { ITableCardSettings } from "@lib";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import { Button, Control } from "@core/components/alt-ui/controls";
import { AltCardListToolboxControl, IAltCardListToolboxContext } from "./alt-card-list-toolbox-control";

export class AltCardListToolbox extends Sidebar<IAltCardListToolboxContext, ITableCardSettings> {
    private cardControl!: AltCardListToolboxControl;

    private btnSave!: Button;
    private btnCancel!: Button;

    private context?: IAltCardListToolboxContext;

    public constructor() {
        super("alt-card-list-toolbox", "НАСТРОЙКИ КАРТОЧЕК");
        this.initializeControls();
    }

    public show(context: IAltCardListToolboxContext): Promise<ITableCardSettings> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.cardControl = new AltCardListToolboxControl();

        if (this.context) {
            this.cardControl.initialize({
                header: this.context.header,
                footer: this.context.footer,
                widgets: this.context.widgets,
                widgetsAll: this.context.widgetsAll,
                api: this.context.api,
            });
        }

        //

        this.btnSave = new Button();
        this.btnSave.id = "alt-card-list-toolbox.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.class = "mr-0.75";
        this.btnSave.variant = "success";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.btnCancel = new Button();
        this.btnCancel.id = "alt-card-list-toolbox.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.variant = "flat-danger";
        this.btnCancel.addClickHandler(() => this.hide());
    }

    public get controls(): Control[] {
        return [this.cardControl];
    }

    public get footerControls(): Control[] {
        return [this.btnSave, this.btnCancel];
    }

    public hide(): void {
        this.cardControl.reset();
        this.hide();
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const settings = this.cardControl.getSettings();

        super.hide(settings);
    }
}
