import { Vue, Component, Prop } from "vue-property-decorator";
import { BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BButton } from "bootstrap-vue";
import { IOrder, IOrderStage, ITimelineStep, TimelineStepType } from "@lib";
import { datetime } from "@/filters/datetime";
import { AltTimeline, AltTimelineItem } from "@/core/alt-ui";
import * as filters from "@/filters";

@Component({
    components: {
        AltTimeline,
        AltTimelineItem,
        BFormGroup,
        BFormTextarea,
        BFormInput,
        BButton,
        BInputGroupAppend,
    },
})
export default class OrderViewTimeline extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Array, default: () => {} })
    private stages!: IOrderStage[];

    @Prop({ type: Array, default: () => [] })
    private employees!: any[];

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private comment: string = "";

    private get timeline(): any[] {
        if (!this.order) {
            return [];
        }

        let timeline = this.order.timeline ? this.order.timeline : [];

        timeline = timeline.sort((a, b) => {
            const dt1 = new Date(a.datetime);
            const dt2 = new Date(b.datetime);
            return dt1 > dt2 ? -1 : dt1 < dt2 ? 1 : 0;
        });

        return timeline.map(step => this.convertTimelineStep(step));
    }

    private convertTimelineStep(step: ITimelineStep): any {
        const id = step.id ? step.id : new Date(step.datetime).getTime();
        const time = datetime(step.datetime);
        const author = this.getEmployeeName(step.author as string);

        switch (step.type) {
            case TimelineStepType.Create:
                return {
                    id: id,
                    color: "success",
                    icon: "PlusIcon",
                    title: `${author} создал(а) заявку`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.Done:
                return {
                    id: id,
                    color: "success",
                    icon: "FlagIcon",
                    title: `${author} закрыл(а) заявку`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.Reopen:
                return {
                    id: id,
                    color: "success",
                    icon: "ArrowUpIcon",
                    title: `${author} снова откыл(а) заявку`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.Comment:
                return {
                    id: id,
                    color: "primary",
                    icon: "MessageSquareIcon",
                    title: `${author} оставил(а) комментарий`,
                    desc: step.text,
                    time: time,
                };
            case TimelineStepType.NotificationSms:
                return {
                    id: id,
                    color: "primary",
                    icon: "SendIcon",
                    title: `${author} отправил(а) смс`,
                    desc: step.text,
                    time: time,
                };
            case TimelineStepType.WorkCreate:
                return {
                    id: id,
                    color: "success",
                    icon: "ToolIcon",
                    title: `${author} добавил(а) работу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.WorkUpdate:
                return {
                    id: id,
                    color: "warning",
                    icon: "ToolIcon",
                    title: `${author} изменил(а) работу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.WorkDelete:
                return {
                    id: id,
                    color: "danger",
                    icon: "ToolIcon",
                    title: `${author} удалил(а) работу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.MaterialCreate:
            case TimelineStepType.MaterialGet:
                return {
                    id: id,
                    color: "success",
                    icon: "GridIcon",
                    title: `${author} добавил(а) материал "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.MaterialUpdate:
                return {
                    id: id,
                    color: "warning",
                    icon: "GridIcon",
                    title: `${author} изменил(а) материал "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.MaterialDelete:
                return {
                    id: id,
                    color: "danger",
                    icon: "GridIcon",
                    title: `${author} удалил(а) материал "${step.text}"`,
                    //desc  : "",
                    time: time,
                };

            case TimelineStepType.TaskCreate:
                return {
                    id: id,
                    color: "success",
                    icon: "CheckSquareIcon",
                    title: `${author} добавил(а) задачу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.TaskUpdate:
                return {
                    id: id,
                    color: "warning",
                    icon: "CheckSquareIcon",
                    title: `${author} изменил(а) задачу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.TaskMove:
                return {
                    id: id,
                    color: "warning",
                    icon: "CheckSquareIcon",
                    title: `${author} переместил(а) задачу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.TaskDelete:
                return {
                    id: id,
                    color: "danger",
                    icon: "CheckSquareIcon",
                    title: `${author} удалил(а) задачу "${step.text}"`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.NumberChanged:
                return {
                    id: id,
                    color: "warning",
                    icon: "InfoIcon",
                    title: `${author} изменил(а) номер заявки на ${step.text}`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.InfoChanged:
                return {
                    id: id,
                    color: "warning",
                    icon: "InfoIcon",
                    title: `${author} изменил(а) данные заявки`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.StageChanged:
                return {
                    id: id,
                    color: "success",
                    icon: "CheckCircleIcon",
                    title: `${author} изменил(а) этап на "${this.getStageName(step.text)}"`,
                    desc: step.description,
                    time: time,
                };
            case TimelineStepType.ClientChanged:
                return {
                    id: id,
                    color: "warning",
                    icon: "UserIcon",
                    title: `${author} изменил(а) клиента`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.ManagerChanged:
                return {
                    id: id,
                    color: "success",
                    icon: "UserIcon",
                    title: `${author} изменил(а) ответственного на ${this.getEmployeeName(step.text)}`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.ProductChanged:
                return {
                    id: id,
                    color: "warning",
                    icon: "TabletIcon",
                    title: `${author} изменил(а) данные устройства`,
                    //desc  : "",
                    time: time,
                };
            case TimelineStepType.PaymentCreate:
                return {
                    id: id,
                    color: "success",
                    icon: "DollarSignIcon",
                    title: `${author} принял(а) средства`,
                    desc: filters.money.money2(step.text, true),
                    time: time,
                };
            case TimelineStepType.PaymentDelete:
                return {
                    id: id,
                    color: "danger",
                    icon: "DollarSignIcon",
                    title: `${author} удалил(а) платёж "${step.description}"`,
                    desc: filters.money.money2(step.text, true),
                    time: time,
                };
        }

        return {
            id: id,
            color: "primary",
            icon: "InfoIcon",
            title: step.text,
            //desc  : "",
            time: time,
        };
    }

    private getEmployeeName(id: string): string {
        if (!this.employees) {
            return id;
        }
        const employee = this.employees.find((emp: any) => emp.user === id);
        return employee ? employee.userRef.info.name : id;
    }

    private getStageName(id: string): string {
        if (!this.stages) {
            return id;
        }
        const stage = this.stages.find((s: any) => s.id === id);
        return stage ? stage.name : id;
    }

    private async addComment(): Promise<void> {
        try {
            if (this.comment.length === 0) {
                return;
            }

            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            const companyId = this.order.company as string;
            const orderId = this.order.id;
            const comment = this.comment;

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.addComment(companyId, orderId, comment);

            this.comment = "";
            this.$emit("order-changed", order);
        } catch {
            this.$alt.toast.error("Не удалось добавить комментарий.");
        }
    }
}
