import parsePhoneNumber from "libphonenumber-js";
import {
    Currency,
    ICompany,
    IOrder,
    IOrderStage,
    IOrderType,
    IProductType,
    ITableCardWidget,
    Locale,
    OrderTypeFieldGroupType,
} from "@lib";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";
import { Printer } from "@/@core/usecases/template/printer";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { CardMoreFunc, ICardMoreItem, ICardWidget } from "@/core/components/alt-card-list";
import { Badge, Control, Icon, Label, Link, Panel } from "@core/components/alt-ui/controls";
import { EventHandler } from "@core/components/alt-ui";
import { getCustomFields } from "./orders-defaults";
import router from "@/router/router";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";

/** Контекст списка каточек заявок. */
export interface IOrderCardsContext {
    company: ICompany;
    orderTypes: IOrderType[];
    productTypes: IProductType[];
    stages: IOrderStage[];
    showOrderStageModal: (order: IOrder) => Promise<void>;
    showOrderUpdateModal: (order: IOrder) => Promise<void>;
    showOrderDeleteModal: (order: IOrder) => Promise<void>;
    canOrderUpdate: (order: IOrder) => boolean;
    canOrderDelete: (order: IOrder) => boolean;
}

/** Методы и значения для карточек заявок. */
export abstract class OrderCards {
    /** Виджет заголовка карточки по умолчанию */
    public static DefaultHeaderWidget: ITableCardWidget | null = { id: "number" };

    /** Виджет подвала карточки по умолчанию */
    public static DefaultFooterWidget: ITableCardWidget | null = { id: "created" };

    /** Виджеты на карточке по умолчанию. */
    public static DefaultWidgets: ITableCardWidget[] = [
        //{ id: "number" },
        { id: "stage" },
        { id: "product.name" },
        { id: "product.description" },
        { id: "client" },
        { id: "manager" },
        //{ id: "created" },
        //{ id: "deadline" },
    ];

    /** Получить функцию для формирования меню карточки. */
    public static getCardMoreFunc(context: IOrderCardsContext): CardMoreFunc<IOrder> {
        return (order: IOrder): ICardMoreItem<IOrder>[] => {
            const items: ICardMoreItem<IOrder>[] = [];

            if (context.canOrderUpdate(order)) {
                items.push({
                    id: "edit",
                    icon: "Edit3Icon",
                    text: "Изменить",
                    click: (order: IOrder) => context.showOrderUpdateModal(order),
                });
            }

            if (context.canOrderDelete(order)) {
                items.push({
                    id: "delete",
                    variant: "danger",
                    icon: "Trash2Icon",
                    text: "Удалить",
                    click: (order: IOrder) => context.showOrderDeleteModal(order),
                });
            }

            return items;
        };
    }

    /** Получить массив всех возможных виджетов. */
    public static getAllWidgets(context: IOrderCardsContext): ICardWidget<IOrder>[] {
        return [...this.getStandartWidgets(context), ...this.getCustomWidgets(context)];
    }

    private static getStandartWidgets(context: IOrderCardsContext): ICardWidget<IOrder>[] {
        return [
            {
                id: "number",
                field: "number",
                name: "#",
                sortable: true,
                getText: (order: IOrder): string => {
                    if (!order.officeRef) {
                        return order.number.toString();
                    }

                    const orderType = context.orderTypes.find((t: IOrderType) => t.id === order.type);

                    if (!orderType) {
                        return order.number.toString();
                    }

                    const printContext: OrderPrintContext = {
                        company: context.company,
                        office: order.officeRef,
                        order: order,
                        orderType: orderType,
                        productTypes: context.productTypes,
                    };

                    return Printer.replaceOrderDocumentsGeneralMacros("%(Заявка.Номер)", printContext);
                },
            },
            {
                id: "office",
                field: "office",
                name: "Филиал",
                sortable: true,
                getText: (order: IOrder): string => {
                    return order.officeRef?.info.name ?? "";
                },
            },
            {
                id: "name",
                field: "info.name",
                name: "Название",
                sortable: true,
                getText: (order: IOrder): string => {
                    return order.info?.name ?? "";
                },
            },
            {
                id: "description",
                field: "info.description",
                name: "Описание",
                sortable: true,
                getText: (order: IOrder): string => {
                    return order.info?.description ?? "";
                },
            },
            {
                id: "product.type",
                field: "products",
                name: "Тип устройства",
                sortable: false,
                getText: (order: IOrder): string => {
                    const product = order.products && order.products.length > 0 ? order.products[0] : null;
                    const typeId = product?.info.type ?? "";
                    const type = context.productTypes.find(t => t.id === typeId);
                    return type?.name ?? "";
                },
            },
            {
                id: "product.name",
                field: "products",
                name: "Устройство",
                sortable: false,
                getText: (order: IOrder): string => {
                    const product = order.products && order.products.length > 0 ? order.products[0] : null;
                    return product?.info.name ?? "";
                },
            },
            {
                id: "product.description",
                field: "products",
                name: "Неисправность",
                sortable: false,
                getText: (order: IOrder): string => {
                    const product = order.products && order.products.length > 0 ? order.products[0] : null;
                    return product?.info.description ?? "";
                },
            },
            {
                id: "stage",
                field: "stage",
                name: "Этап",
                sortable: true,
                getControl: (order: IOrder): Control => {
                    const stage = context.stages.find(stage => stage.id === order.stage);

                    const badge = new Badge();
                    badge.class = "w-content px-1 py-0.25";
                    badge.style = `background-color: ${stage ? stage.color : "dark"}`;
                    badge.text = stage ? stage.name : "-";
                    badge.addClickHandler((s, e) => context.showOrderStageModal(order));

                    return badge;
                },
            },
            {
                id: "price",
                name: "Цена",
                sortable: false,
                getText: (order: IOrder): string => {
                    let sum = 0;

                    if (order.materials) {
                        for (const material of order.materials) {
                            sum += material.price * material.quantity;
                        }
                    }

                    if (order.works) {
                        for (const work of order.works) {
                            sum += work.price * work.quantity;
                        }
                    }

                    const currency = order.officeRef?.info.currency ?? Currency.RUB;
                    const locale = order.officeRef?.info.locale ?? Locale.RU;
                    return Localizer.currency(sum, currency, locale);
                },
            },
            {
                id: "client",
                field: "client",
                name: "Клиент",
                sortable: true,
                // getControl: (order: IOrder): Control => {
                //     const icon = new Icon();
                //     icon.class = "mr-0.5";
                //     icon.icon = "UserIcon";

                //     const label = new Label();
                //     label.class = "text-base";
                //     label.text = order.clientRef?.info.name ?? "";

                //     const panel = new Panel();
                //     panel.class = "flex";
                //     panel.addControls([icon, label]);

                //     return panel;
                // },
                getControl: (order: IOrder): Control => {
                    const client = order.clientRef;
                    if (!client) {
                        return new Panel();
                    }

                    const elements = new Panel();

                    const name = this.createLinkWithIcon(client.info.name, "UserIcon", (s, e) => {
                        router.push({ name: "clients", query: { id: client.id } }).catch(() => {});
                    });

                    elements.addControl(name);

                    if (client.info.contacts?.phone) {
                        const clientPhone = client.info.contacts.phone;
                        const phoneNumber = parsePhoneNumber(clientPhone);

                        const phone = this.createLinkWithIcon(
                            phoneNumber?.formatInternational() ?? clientPhone,
                            "PhoneIcon",
                            (s, e) => {
                                window.open(`tel:${client.info.contacts.phone}`);
                            },
                        );

                        elements.addControl(phone);
                    }

                    if (client.info.contacts?.email) {
                        const mail = this.createLinkWithIcon(client.info.contacts.email, "MailIcon", (s, e) => {
                            window.open(`mailto:${client.info.contacts.email}`);
                        });

                        elements.addControl(mail);
                    }

                    return elements;
                },
            },
            {
                id: "manager",
                field: "manager",
                name: "Ответственный",
                sortable: true,
                getControl: (order: IOrder): Control => {
                    return this.createLabelWithIcon(order.managerRef?.info.name ?? "", "UserCheckIcon");
                },
            },
            {
                id: "created",
                field: "createdAt",
                name: "Дата создания",
                sortable: true,
                getText: (order: IOrder): string => {
                    return filters.datetime.datetime(order.createdAt, "L LT");
                },
                // getControl: (order: IOrder): Control => {
                //     const icon = new Icon();
                //     icon.class = "mr-0.5";
                //     icon.icon = "ClockIcon";

                //     const label = new Label();
                //     label.class = "text-base";
                //     label.text = filters.datetime.datetime(order.createdAt, "L LT");

                //     const panel = new Panel();
                //     panel.class = "flex";
                //     panel.addControls([icon, label]);

                //     return panel;
                // },
            },
            {
                id: "doneAt",
                field: "doneAt",
                name: "Дата завершения",
                sortable: true,
                getText: (order: IOrder): string => {
                    if (!order.done || !order.doneAt) {
                        return "";
                    }

                    return filters.datetime.datetime(order.doneAt, "L LT");
                },
            },
            {
                id: "deadline",
                field: "info.deadline",
                name: "Крайний срок",
                sortable: true,
                getText: (order: IOrder): string => {
                    return order.info?.deadline ? filters.datetime.datetime(order.info.deadline, "L LT") : "";
                },
            },
        ];
    }

    private static getCustomWidgets(context: IOrderCardsContext): ICardWidget<IOrder>[] {
        const customWidgets: ICardWidget<IOrder>[] = [];
        const groupedCustomFields = getCustomFields(context);
        const customFieldIds: string[] = [];

        for (const gf of groupedCustomFields) {
            // убираем повторяющиеся поля
            if (customFieldIds.includes(gf.field.id)) {
                continue;
            }

            const { field, group } = gf;
            customFieldIds.push(field.id);

            customWidgets.push({
                id: field.id,
                name: field.title ?? "<Новое поле>",
                sortable: false,
                getText: (order: IOrder): string => {
                    // TODO: повтор, как в order-macro-opener
                    const key = field.customId ?? field.details1 ?? field.id;
                    let value: any;

                    if (group.type === OrderTypeFieldGroupType.Client) {
                        value = order.clientRef?.custom ? order.clientRef.custom[key] : undefined;
                    } else if (group.type === OrderTypeFieldGroupType.Product && order.products.length > 0) {
                        value = order.products[0].custom ? order.products[0].custom[key] : undefined;
                    } else {
                        value = order.custom ? order.custom[key] : undefined;
                    }

                    const locale = order?.officeRef?.info?.locale;
                    return FieldControlFactory.formatValue(value, field, { locale });
                },
            });
        }

        return customWidgets;
    }

    private static createLabelWithIcon(text: string, icon: string): Control {
        const iconControl = new Icon();
        iconControl.class = "mr-0.5";
        iconControl.icon = icon;

        const labelControl = new Label();
        labelControl.class = "text-base";
        labelControl.text = text;

        const panel = new Panel();
        panel.class = "flex";
        panel.addControls([iconControl, labelControl]);
        return panel;
    }

    private static createLinkWithIcon(text: string, icon: string, clickHandler: EventHandler): Control {
        const iconControl = new Icon();
        iconControl.class = "mr-0.5";
        iconControl.icon = icon;

        const linkControl = new Link();
        //name.class = "text-dark hover:underline";
        linkControl.class = "text-primary hover:underline";
        linkControl.text = text;
        linkControl.addClickHandler(clickHandler);

        const panel = new Panel();
        panel.class = "flex";
        panel.addControls([iconControl, linkControl]);
        return panel;
    }
}
