import VueRouter from "vue-router";
import { IUser } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Label, Link, Panel } from "@core/components/alt-ui/controls";

export interface IWelcomeModalContext {
    user: IUser;
    router: VueRouter;
}

export class WelcomeModal extends Modal<IWelcomeModalContext> {
    private lbHeader!: Label;

    private lbDemo!: Label;

    private lbSettings!: Label;
    private lbSettingsLink!: Link;
    private pnlSettings!: Panel;

    private lbPayment!: Label;
    private lbPaymentLink!: Link;
    private pnlPayment!: Panel;

    private pnlFooter!: Panel;
    private btnGo!: Button;

    private context: IWelcomeModalContext | null = null;
    public onModalHide: (() => void) | null = null;

    public constructor() {
        super("welcome-modal", "Добро пожаловать!");
        this.addVisibleChangedHandler(this.changeModalVisible.bind(this));
        this.initializeControls();
    }

    public show(context: IWelcomeModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        return super.show();
    }

    protected initializeControls(): void {
        this.lbHeader = new Label();
        this.lbHeader.class = "font-semibold text-center text-xl mt-0.5 mb-1";
        this.lbHeader.text = "Благодарим за регистрацию!";

        if (this.context) {
            this.lbHeader.text = `${this.context.user.info.name}! ${this.lbHeader.text}`;
        }

        this.lbDemo = new Label();
        this.lbDemo.class = "text-base mr-0.5";
        this.lbDemo.style = "white-space: normal!important";
        this.lbDemo.text = "В демо режиме первые 10 заявок и продаж - бесплатно!";

        this.lbSettings = new Label();
        this.lbSettings.class = "text-base mr-0.5";
        this.lbSettings.text = "Изменить настройки компании можно";
        //<b-link href.stop @click.stop.prevent="settings" class="hover:underline" tabindex="-1">тут</b-link>

        this.lbSettingsLink = new Link();
        this.lbSettingsLink.class = "text-base";
        this.lbSettingsLink.text = "тут";
        this.lbSettingsLink.addClickHandler(this.clickSettings.bind(this));

        this.pnlSettings = new Panel();
        this.pnlSettings.class = "flex";
        this.pnlSettings.addControls([this.lbSettings, this.lbSettingsLink]);

        this.lbPayment = new Label();
        this.lbPayment.class = "text-base mr-0.5";
        this.lbPayment.text = "Оплатить доступ можно";
        //<b-link href.stop @click.stop.prevent="pay" class="hover:underline" tabindex="-1">тут</b-link>

        this.lbPaymentLink = new Link();
        this.lbPaymentLink.class = "text-base";
        this.lbPaymentLink.text = "тут";
        this.lbPaymentLink.addClickHandler(this.clickPayment.bind(this));

        this.pnlPayment = new Panel();
        this.pnlPayment.class = "flex";
        this.pnlPayment.addControls([this.lbPayment, this.lbPaymentLink]);

        //

        this.btnGo = new Button();
        this.btnGo.id = "welcome.go";
        this.btnGo.text = "Вперёд!";
        this.btnGo.addClickHandler(this.clickGo.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnGo]);
    }

    public get controls(): Control[] {
        return [this.lbHeader, this.lbDemo, this.pnlSettings, this.pnlPayment, this.pnlFooter];
    }

    private async changeModalVisible(sender: any, e: any): Promise<void> {
        if (!e.visible) {
            if (this.onModalHide) {
                this.onModalHide();
            }
        }
    }

    private async clickSettings(): Promise<void> {
        try {
            await this.context?.router.push({ name: "settings-company" });
        } catch {}
    }

    private async clickPayment(): Promise<void> {
        try {
            await this.context?.router.push("/settings/company/payments");
        } catch {}
    }

    private async clickGo(sender: any, e: any): Promise<void> {
        this.hide();
    }
}
