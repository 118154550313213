import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { IAccount, IOffice, IOrderType } from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import AltIcon from "@/core/components/alt-icon";
import { OrderBlockPaymentHandler } from "./order-block-payment-handler";

@Component({
    components: { ControlComponent, AltIcon },
})
export default class OrderCreatePayment extends Vue {
    @Prop({ required: true })
    private handler!: OrderBlockPaymentHandler;

    @Prop({ default: () => null })
    private office!: IOffice | null;

    @Prop({ default: () => null })
    private orderType!: IOrderType | null;

    @Prop({ default: () => [] })
    private accounts!: IAccount[];

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    @Watch("office", { immediate: true, deep: true })
    private onOfficeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        this.handler.init(this.office, this.orderType, this.accounts);
    }
}
