import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { BSidebar, BButton, VBTooltip } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { IOrder, IOrderWork, IOrderMaterial, IOrderStage, IOrderType, IPluginBinding, ICompany, IUser } from "@lib";
import { IAccount, IStore, IClientSource, IProductType } from "@lib";
import { ITemplateDocument, ITemplateNotification, PluginType } from "@lib";
import { ModalComponent } from "@core/components/alt-ui/modal";
import OrderViewTitle from "./order-view-title.vue";
import OrderViewStage from "./order-view-stage.vue";
import OrderViewManager from "./order-view-manager.vue";
import OrderViewPrint from "./order-view-print.vue";
import OrderViewNotification from "./order-view-notification.vue";
import OrderViewInfo from "./order-view-info.vue";
import OrderViewPayments from "./order-view-payments.vue";
import OrderViewClientExisting from "./order-view-client-existing.vue";
import OrderViewProduct from "./order-view-product.vue";
import OrderViewTabs from "./order-view-tabs/order-view-tabs.vue";
import OrderViewController from "./orderViewController";
import { OrderViewCompleteModal } from "./order-view-complete.modal";
import { OrderViewReopenModal } from "./order-view-reopen.modal";

@Component({
    name: "order-view-form",
    components: {
        BSidebar,
        BButton,
        VuePerfectScrollbar,
        ModalComponent,
        OrderViewTitle,
        OrderViewStage,
        OrderViewManager,
        OrderViewPrint,
        OrderViewNotification,
        OrderViewInfo,
        OrderViewClientExisting,
        OrderViewProduct,
        OrderViewTabs,
        OrderViewPayments,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class OrderViewForm extends Vue {
    @PropSync("show", { type: Boolean, default: false })
    private showSync!: boolean;

    @Prop({ type: Object, required: true })
    private value!: OrderViewController;

    @Prop({ type: Object, default: () => null })
    private order!: IOrder | null;

    @Prop({ type: Array, required: true })
    private orderStages!: IOrderStage[];

    @Prop({ type: Array, required: true })
    private orderTypes!: IOrderType[];

    @Prop({ type: Array, required: true })
    private clientSources!: IClientSource[];

    @Prop({ type: Array, required: true })
    private productTypes!: IProductType[];

    @Prop({ type: Array, default: () => [] })
    private documents!: ITemplateDocument[];

    @Prop({ type: Array, default: () => [] })
    private smss!: ITemplateNotification[];

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => [] })
    private employees!: any[];

    @Prop({ type: Boolean, default: false })
    private canUpdate!: boolean;

    @Prop({ type: Boolean, default: false })
    private canDelete!: boolean;

    @Prop({ type: Boolean, default: false })
    private canEditOrderType!: boolean;

    @Prop({ type: Object, required: true })
    private completeModal!: OrderViewCompleteModal;

    private user!: IUser;
    private company!: ICompany;
    private orderEx: any = {};

    private plugins: IPluginBinding[] = [];
    private clients: any[] = [];

    private scrollSettings: object = {
        //maxScrollbarLength: 60,
        wheelSpeed: 0.5,
    };

    private reopenModal: OrderViewReopenModal;

    constructor() {
        super();

        this.reopenModal = new OrderViewReopenModal();
        this.reopenModal.onReopen = this.reopen.bind(this);
    }

    private get orderType(): IOrderType | undefined {
        const type = this.orderEx.type;
        return this.orderTypes.find(t => t.id === type);
    }

    private get works(): IOrderWork[] {
        return this.orderEx && this.orderEx.works ? this.orderEx.works : [];
    }

    private get materials(): IOrderMaterial[] {
        return this.orderEx && this.orderEx.materials ? this.orderEx.materials : [];
    }

    private get timeline(): any[] {
        return this.orderEx && this.orderEx.timeline ? this.orderEx.timeline : [];
    }

    private get product(): any {
        return this.orderEx && this.orderEx.products && this.orderEx.products.length > 0
            ? this.orderEx.products[0]
            : {};
    }

    private get notificationPlugins(): IPluginBinding[] {
        return this.plugins.filter(p => p.type === PluginType.Sms && p.enabled);
    }

    // @Watch("show")
    // private onShowChanged(value: boolean): void {
    //     if (value) {
    //         this.initValues();
    //     }
    // }

    @Watch("order")
    private onOrderInChanged(value: IOrder): void {
        if (value) {
            this.initValues();
        }
    }

    public async mounted(): Promise<void> {
        await this.initValues();
    }

    private async initValues(): Promise<void> {
        this.user = await this.$info.getUser();
        this.company = await this.$info.getCompany();
        this.plugins = await this.$info.getPlugins();

        if (!this.order) {
            return;
        }

        this.orderEx = {
            _id: undefined,
            info: {
                name: "",
                description: "",
                deadline: "",
            },
            stage: {},
            products: [
                {
                    info: {
                        sku: "",
                        name: "",
                        description: "",
                        brand: "",
                        model: "",
                    },
                },
            ],
            works: [],
            materials: [],
            timeline: [],
        };

        this.clients = [];

        if (this.orderStages && this.orderStages.length > 0) {
            this.orderEx.stage = this.orderStages[0].id;
        }

        this.orderEx = this.$alt.clone(this.order);
    }

    private cancel(): void {
        //this.showSync = false;
        this.value.close();
    }

    private async startComplete(): Promise<void> {
        if (!this.order) {
            return;
        }

        const documents = await this.$settings.getDocumentsOrderComplete();
        const documentsSelected = this.documents.filter(d => documents.includes(d.id));

        const context = {
            stages: this.orderStages,
            accounts: this.accounts,
            documents: this.documents,
            documentsSelected: documentsSelected,
            order: this.order,
        };
        await this.completeModal.show(context);
    }

    private async showReopenModal(): Promise<void> {
        const context = {
            stages: this.orderStages,
        };
        await this.reopenModal.show(context);
    }

    private async search(search: string, loading: Function): Promise<void> {
        /*try {
            if (search.length === 0)
                return;

            loading(true);
            this.clients = await this.controller.searchClients(search);
        } finally {
            loading(false);
        }*/
    }

    private async reopen(stage: IOrderStage): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const order = await usecase.reopen(this.order.company as string, this.order.id, stage.id);

            this.$alt.toast.success("Заявка снова открыта.");
            this.changeOrder(order);
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось открыть заявку:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private changeOrder(order: IOrder): void {
        //this.orderEx = this.$alt.clone(order);
        this.$emit("order-changed", order, this.order);
    }

    private deleteOrder(): void {
        this.$emit("order-delete", this.order);
    }

    private toEditOrderType(): void {
        this.$router.push({ name: "settings-lists", query: { id: this.order?.type } }).catch(() => {});
    }
}
