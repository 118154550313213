import { ITableCardSettings } from "@lib";
import { Button, ComboBox, Control, Icon, Label, Panel } from "@core/components/alt-ui/controls";
import { CardListApi, ICardWidget } from ".";
import { Uuid } from "@/utils/uuid";
import { ObjectUtils } from "@/utils/object.utils";

export interface IAltCardListToolboxContext {
    header?: ICardWidget;
    footer?: ICardWidget;
    widgets?: ICardWidget[];
    widgetsAll: ICardWidget[];
    api: CardListApi;
}

type CreateWidgetOptions = {
    change: (item: ICardWidget | null) => void;
    remove: () => void;
};

type CreateMultiWidgetOptions = {
    change: (items: ICardWidget[]) => void;
};

export class AltCardListToolboxControl extends Panel {
    private lbCardHeaderTitle!: Label;
    private pnlCardHeaderField!: Panel;
    private pnlCardHeader!: Panel;

    private lbCardWidgetsTitle!: Label;
    private pnlCardWidgetsFields!: Panel;
    private btnCardWidgetsAdd!: Button;
    private pnlCardWidgets!: Panel;

    private lbCardFooterTitle!: Label;
    private pnlCardFooterField!: Panel;
    private pnlCardFooter!: Panel;

    private context?: IAltCardListToolboxContext;

    private headerBackup: ICardWidget | null = null;
    private footerBackup: ICardWidget | null = null;
    private widgetsBackup: ICardWidget[] | null = null;

    public constructor() {
        super();
        this.initializeControls();
    }

    public initialize(context: IAltCardListToolboxContext): void {
        this.context = context;

        this.headerBackup = ObjectUtils.clone(this.context.header) ?? null;
        this.footerBackup = ObjectUtils.clone(this.context.footer) ?? null;
        this.widgetsBackup = this.context.widgets ? ObjectUtils.clone(this.context.widgets) : null;

        this.initializeControls();
    }

    private initializeControls(): void {
        this.lbCardHeaderTitle = new Label();
        this.lbCardHeaderTitle.id = "alt-card-list-toolbox.header.title";
        this.lbCardHeaderTitle.class = "font-semibold text-secondary mb-1";
        this.lbCardHeaderTitle.style = "font-size: 12px";
        this.lbCardHeaderTitle.text = "ЗАГОЛОВОК";

        this.pnlCardHeaderField = this.createWidget({
            change: item => this.context?.api.changeHeader(item),
            remove: () => this.context?.api.changeHeader(null),
        });
        // this.pnlCardHeaderField.id = "alt-card-list-toolbox.header.field";
        // this.pnlCardHeaderField.addControl(this.createHeaderSelect());
        if (this.pnlCardHeaderField?.controls[0] as ComboBox<ICardWidget>) {
            (this.pnlCardHeaderField.controls[0] as ComboBox<ICardWidget>).selectBy(
                i => i.id === this.headerBackup?.id,
            );
        }

        this.pnlCardHeader = new Panel();
        this.pnlCardHeader.id = "alt-card-list-toolbox.header";
        this.pnlCardHeader.class = "mb-3";
        this.pnlCardHeader.addControls([this.lbCardHeaderTitle, this.pnlCardHeaderField]);

        //

        this.lbCardWidgetsTitle = new Label();
        this.lbCardWidgetsTitle.id = "alt-card-list-toolbox.widgets.title";
        this.lbCardWidgetsTitle.class = "font-semibold text-secondary mb-1";
        this.lbCardWidgetsTitle.style = "font-size: 12px";
        this.lbCardWidgetsTitle.text = "СОДЕРЖИМОЕ";

        this.pnlCardWidgetsFields = new Panel();
        this.pnlCardWidgetsFields.id = "alt-card-list-toolbox.widgets.fields";

        if (this.widgetsBackup) {
            for (const widget of this.widgetsBackup) {
                const pnl = this.createMultiWidget({
                    change: items => this.context?.api.changeWidgets(items),
                });

                if (pnl?.controls[0] as ComboBox<ICardWidget>) {
                    (pnl.controls[0] as ComboBox<ICardWidget>).selectBy(i => i.id === widget.id);
                }

                this.pnlCardWidgetsFields.addControl(pnl);

                const widgets = this.getCardWidgets();
                this.context?.api.changeWidgets(widgets);
            }
        }

        this.btnCardWidgetsAdd = new Button();
        this.btnCardWidgetsAdd.id = "alt-card-list-toolbox.widgets.add";
        this.btnCardWidgetsAdd.text = "Добавить поле";
        this.btnCardWidgetsAdd.variant = "outline-success";
        this.btnCardWidgetsAdd.class = "w-100";
        this.btnCardWidgetsAdd.addClickHandler((s, e) => {
            this.pnlCardWidgetsFields.addControl(
                this.createMultiWidget({
                    change: items => this.context?.api.changeWidgets(items),
                }),
            );
        });

        this.pnlCardWidgets = new Panel();
        this.pnlCardWidgets.id = "alt-card-list-toolbox.widgets";
        this.pnlCardWidgets.class = "mb-3";
        this.pnlCardWidgets.addControls([this.lbCardWidgetsTitle, this.pnlCardWidgetsFields, this.btnCardWidgetsAdd]);

        //

        this.lbCardFooterTitle = new Label();
        this.lbCardFooterTitle.id = "alt-card-list-toolbox.footer.title";
        this.lbCardFooterTitle.class = "font-semibold text-secondary mb-1";
        this.lbCardFooterTitle.style = "font-size: 12px";
        this.lbCardFooterTitle.text = "ПОДВАЛ";

        this.pnlCardFooterField = this.createWidget({
            change: item => this.context?.api.changeFooter(item),
            remove: () => this.context?.api.changeFooter(null),
        });
        // this.pnlCardFooterField.id = "alt-card-list-toolbox.footer.field";
        // this.pnlCardFooterField.addControl(this.createHeaderSelect());
        if (this.pnlCardFooterField?.controls[0] as ComboBox<ICardWidget>) {
            (this.pnlCardFooterField.controls[0] as ComboBox<ICardWidget>).selectBy(
                i => i.id === this.footerBackup?.id,
            );
        }

        this.pnlCardFooter = new Panel();
        this.pnlCardFooter.id = "alt-card-list-toolbox.footer";
        this.pnlCardFooter.class = "mb-3";
        this.pnlCardFooter.addControls([this.lbCardFooterTitle, this.pnlCardFooterField]);
    }

    private createWidget(options?: CreateWidgetOptions): Panel {
        const id = Uuid.new();

        const select = new ComboBox<ICardWidget | null>();
        select.style = "width: 90%";
        select.items = this.context?.widgetsAll ?? [];
        select.textField = w => w?.name ?? "<Не выбрано>";
        select.selectedItem = null;
        select.addChangedHandler((s, e) => {
            options?.change(e.item);
        });

        const iconDelete = new Icon();
        iconDelete.icon = "Trash2Icon";
        iconDelete.class = "cursor-pointer text-danger ml-0.75";
        iconDelete.addClickHandler((s, e) => {
            select.selectedItem = null;
            options?.remove();
        });

        const pnl = new Panel();
        pnl.id = id;
        pnl.class = "w-100 flex flex-wrap items-center mb-1";
        pnl.addControls([select, iconDelete]);

        return pnl;
    }

    private createMultiWidget(options?: CreateMultiWidgetOptions): Panel {
        const id = Uuid.new();

        const select = new ComboBox<ICardWidget>();
        select.style = "width: 72%";
        select.items = this.context?.widgetsAll ?? [];
        select.textField = w => w.name;
        select.selectedIndex = 0;
        select.addChangedHandler((s, e) => {
            const widgets = this.getCardWidgets();
            options?.change(widgets);
        });

        const iconUp = new Icon();
        iconUp.icon = "ArrowUpIcon";
        iconUp.class = "cursor-pointer ml-0.75";
        iconUp.addClickHandler((s, e) => {
            ObjectUtils.upArrayItem(this.pnlCardWidgetsFields.controls, i => i.id === id);
            const widgets = this.getCardWidgets();
            options?.change(widgets);
        });

        const iconDown = new Icon();
        iconDown.icon = "ArrowDownIcon";
        iconDown.class = "cursor-pointer ml-0.75";
        iconDown.addClickHandler((s, e) => {
            ObjectUtils.downArrayItem(this.pnlCardWidgetsFields.controls, i => i.id === id);
            const widgets = this.getCardWidgets();
            options?.change(widgets);
        });

        const iconDelete = new Icon();
        iconDelete.icon = "Trash2Icon";
        iconDelete.class = "cursor-pointer text-danger ml-0.75";
        iconDelete.addClickHandler((s, e) => {
            ObjectUtils.deleteArrayItem(this.pnlCardWidgetsFields.controls, i => i.id === id);
            const widgets = this.getCardWidgets();
            options?.change(widgets);
        });

        const pnl = new Panel();
        pnl.id = id;
        pnl.class = "w-100 flex flex-wrap items-center mb-1";
        pnl.addControls([select, iconUp, iconDown, iconDelete]);

        return pnl;
    }

    private getCardWidgets(): ICardWidget[] {
        return this.pnlCardWidgetsFields.controls
            .map(p => ((p as Panel)?.controls[0] as ComboBox<ICardWidget>)?.selectedItem)
            .filter(w => w !== null) as ICardWidget[];
    }

    public get controls(): Control[] {
        return [this.pnlCardHeader, this.pnlCardWidgets, this.pnlCardFooter];
    }

    public reset(): void {
        this.context?.api.changeHeader(this.headerBackup);
        this.context?.api.changeFooter(this.footerBackup);
        this.context?.api.changeWidgets(this.widgetsBackup);
    }

    public getSettings(): ITableCardSettings {
        const header = (this.pnlCardHeaderField?.controls[0] as ComboBox<ICardWidget>)?.selectedItem;
        const footer = (this.pnlCardFooterField?.controls[0] as ComboBox<ICardWidget>)?.selectedItem;
        const widgets = this.getCardWidgets();

        const settings: ITableCardSettings = {
            header: header ? { id: header.id } : null,
            footer: footer ? { id: footer.id } : null,
            widgets: widgets.map(w => ({ id: w.id })),
        };

        return settings;
    }
}
