import {
    IAccount,
    IOffice,
    IOrderCreatePaymentDto,
    IOrderProductChangeDto,
    IOrderType,
    IOrderTypeField,
    IOrderTypeFieldGroup,
    IProductInfo,
    OrderTypeFieldGroupType,
} from "@lib";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";

export class OrderBlockPaymentHandler {
    public controls: FieldControl[] = [];

    private office: IOffice | null = null;
    private orderType: IOrderType | null = null;
    private accounts: IAccount[] = [];
    private fieldGroup: IOrderTypeFieldGroup | null = null;

    public get title(): string {
        return this.fieldGroup?.name ?? "Оплата";
    }

    public get icon(): string {
        return this.fieldGroup?.icon ?? "bank-card";
    }

    public init(office: IOffice | null, orderType: IOrderType | null, accounts: IAccount[]): void {
        this.office = office;
        this.orderType = orderType;
        this.accounts = accounts;

        if (!this.office || !this.orderType) {
            return;
        }

        this.fieldGroup = this.orderType.groups.find(g => g.type === OrderTypeFieldGroupType.Payment) ?? null;

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.office?.info?.locale,
            accounts: this.accounts,
        };

        for (const field of this.getFields()) {
            const fieldControl = FieldControlFactory.fromField(field, context);
            this.controls.push(fieldControl);
        }
    }

    private getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    public getDto(): IOrderCreatePaymentDto | undefined {
        const payment: any = {};

        for (const fieldControl of this.controls) {
            const value = fieldControl.value;
            this.populateStandartFieldValue(payment, fieldControl.field, value);
        }

        const value = parseFloat(payment.value);

        if (isNaN(value) || value <= 0) {
            return undefined;
        }

        return {
            account: payment.account,
            value: value,
            description: "Предоплата",
        };
    }

    private populateStandartFieldValue(payment: any, field: IOrderTypeField, value: any): void {
        switch (field.id) {
            case "payment.value":
                payment.value = value;
                return;
            case "payment.account":
                payment.account = value;
                return;
        }
    }

    public clean(): void {
        this.initControls();
    }
}
