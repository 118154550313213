import { Vue, Component, Watch } from "vue-property-decorator";
import { BAlert, BButton, BModal } from "bootstrap-vue";
import {
    IOrder,
    IOffice,
    IAccount,
    IStore,
    IEmployee,
    ITable,
    IUser,
    ICompany,
    IOrderInfoChangeDto,
    IPluginBinding,
    IPluginNotificationSendDto,
    OrderStageType,
    IReadQuery,
    ITableColumn,
    ITableCardSettings,
    TableView,
} from "@lib";
import { IOrderCreatePaymentDto, IOrderCreateDto, IOrderDeleteManyDto } from "@lib";
import { ISelectQuery, IClientCreateDto, ISettingsTableUpdateDto } from "@lib";
import { ITemplateDocument, TemplateDocumentType, ITemplateNotification, TemplateNotificationType } from "@lib";
import { IOrderType, IOrderStage, IProductType, IClient, IClientSource } from "@lib";
import { FormType, ITableFilter, ITableSort, TableType } from "@lib";
import { PermissionType, PermissionCommonSection, PermissionRight } from "@lib";
import AltUniversalTable from "@/core/components/alt-universal-table";
import { TableApi } from "@/core/components/alt-table";
import { ICardWidget, ICardSortField, CardMoreFunc } from "@/core/components/alt-card-list";
import AltIcon from "@/core/components/alt-icon";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Button, Icon } from "@core/components/alt-ui/controls";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderPrintContext } from "@core/usecases/template/macro-replacers/order-document.macro-replacer";
import OrdersToolbar from "./orders-toolbar/orders-toolbar.vue";
import { getDefaultTableActions, getDefaultTableColumns } from "./orders-defaults";
import { IOrderCardsContext, OrderCards } from "./orders-defaults-cards";
import OrderCreateForm from "./order-create-form/order-create-form.vue";
import OrderCreateController from "./order-create-form/orderCreateController";
import OrderViewForm from "./order-view-form/order-view-form.vue";
import OrderViewController from "./order-view-form/orderViewController";
import { OrderStageModal } from "./order-stage.modal";
import { WelcomeModal } from "./welcome.modal";
import { IOrdersFilterContext, OrdersFilterController } from "./orders-filter-controller";
import { OrderViewCompleteModal } from "./order-view-form/order-view-complete.modal";
import { AppException } from "@/core/exceptions";

@Component({
    components: {
        BModal,
        BAlert,
        BButton,
        AltUniversalTable,
        OrdersToolbar,
        OrderCreateForm,
        OrderViewForm,
        ModalComponent,
        AltIcon,
    },
})
export default class Orders extends Vue {
    private OrderUseCase = this.$alt.system.usecase.createOrderUseCase();
    private ClientUseCase = this.$alt.system.usecase.createClientUseCase();
    private SettingsTable = this.$alt.system.usecase.createSettingsTableUseCase();

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private selectedOffices: IOffice[] = [];
    private stages: IOrderStage[] = [];
    private documents: ITemplateDocument[] = [];
    private smss: ITemplateNotification[] = [];
    private orders: IOrder[] = [];
    private ordersTotal: number = 0;
    private orderTypes: IOrderType[] = [];
    private clientSources: IClientSource[] = [];
    private productTypes: IProductType[] = [];
    private plugins: IPluginBinding[] = [];

    private formOrderCreate = new OrderCreateController(this.create, this.searchClients);

    private formOrderView = new OrderViewController();

    private needPaymentShow = false;
    private notificationShow = false;

    private table: any = {
        settings: {
            view: {
                desktop: TableView.Table,
                mobile: TableView.Cards,
            },
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
            cards: undefined,
        },
        page: 1,
        search: "",

        forceUpdate: false,
        update(): void {
            this.forceUpdate = true;
        },
    };

    public filterController: OrdersFilterController;

    public get filterContext(): IOrdersFilterContext {
        return {
            offices: this.offices,
            stages: this.stages,
            employees: this.employees,

            defaultFilter: {
                office: this.selectedOffices.map(office => office.id),
            },

            currentFilter: this.filter,
        };
    }

    private orderStageModal: OrderStageModal;
    private welcomeModal: WelcomeModal;
    public completeModal: OrderViewCompleteModal;

    constructor() {
        super();

        this.completeModal = new OrderViewCompleteModal();
        this.completeModal.onComplete = this.complete.bind(this);

        this.orderStageModal = new OrderStageModal();
        this.orderStageModal.onChange = this.changeStage.bind(this);

        this.welcomeModal = new WelcomeModal();

        this.filterController = new OrdersFilterController();
        this.filterController.onSave = this.saveFilter.bind(this);
    }

    private get formType(): FormType {
        return FormType.Order;
    }

    private get tableType(): TableType {
        return TableType.Order;
    }

    private offices: IOffice[] = [];
    private stores: IStore[] = [];
    private accounts: IAccount[] = [];
    private employees: IEmployee[] = [];

    private get isSmallScreen(): boolean {
        return this.$info.ui.isMobileView;
    }

    private get orderStagesData(): IOrderStage[] {
        return this.stages ?? [];
    }

    private get orderTypesData(): IOrderType[] {
        return this.orderTypes ?? [];
    }

    private get clientSourcesData(): IClientSource[] {
        return this.clientSources ?? [];
    }

    private get productTypesData(): IProductType[] {
        return this.productTypes ?? [];
    }

    private get ordersData(): IOrder[] {
        return this.orders ?? [];
    }

    private get ordersDataTotal(): number {
        return this.ordersTotal ?? 0;
    }

    protected get defaulColumns(): any[] {
        return getDefaultTableColumns(this, this.showOrderStageModal);
    }

    protected get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    protected get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings.columns);
    }

    protected get limit(): number {
        return this.table.settings.limit ?? TableApi.DefaultLimit;
    }

    protected get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    protected get sort(): ITableSort[] {
        return this.table.settings.sort ?? [];
    }

    protected get filter(): ITableFilter[] {
        return this.table.settings.filter ?? [];
    }

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "office");
    }

    private get cardsSettings(): ITableCardSettings | undefined {
        return this.table.settings.cards;
    }

    private get tableView(): TableView {
        return this.$info.ui.isMobileView
            ? this.table.settings.view?.mobile ?? TableView.Cards
            : this.table.settings.view?.desktop ?? TableView.Table;
    }

    private get hasOrders(): boolean {
        return this.ordersData.length > 0 || this.hasFilter || this.table.search.length > 0;
    }

    private get cards(): any {
        return {
            widgetsAll: this.cardWidgetsAll,
            header: this.cardHeader,
            footer: this.cardFooter,
            widgets: this.cardWidgets,
            sortFields: this.cardSortFields,
            moreFunc: this.cardMoreFunc,
        };
    }

    private get cardListContext(): IOrderCardsContext {
        return {
            company: this.company,
            orderTypes: this.orderTypes,
            productTypes: this.productTypes,
            stages: this.stages,
            showOrderStageModal: this.showOrderStageModal,
            showOrderUpdateModal: this.openFormUpdate,
            showOrderDeleteModal: this.confirmDelete,
            canOrderUpdate: this.can.update,
            canOrderDelete: this.can.delete,
        };
    }

    private get cardWidgetsAll(): ICardWidget<IOrder>[] {
        return OrderCards.getAllWidgets(this.cardListContext);
    }

    private get cardHeader(): ICardWidget<IOrder> | undefined {
        const widget =
            this.cardsSettings?.header !== undefined ? this.cardsSettings.header : OrderCards.DefaultHeaderWidget;
        return this.cardWidgetsAll.find(w => w.id === widget?.id);
    }

    private get cardFooter(): ICardWidget<IOrder> | undefined {
        const widget =
            this.cardsSettings?.footer !== undefined ? this.cardsSettings.footer : OrderCards.DefaultFooterWidget;
        return this.cardWidgetsAll.find(w => w.id === widget?.id);
    }

    private get cardWidgets(): ICardWidget<IOrder>[] {
        const widgets =
            this.cardsSettings?.widgets !== undefined ? this.cardsSettings.widgets : OrderCards.DefaultWidgets;
        const allWidgets = this.cardWidgetsAll;

        return widgets
            .filter(w => !!allWidgets.find(dw => dw.id === w.id))
            .map(w => allWidgets.find(dw => dw.id === w.id) as ICardWidget<IOrder>);
    }

    private get cardSortFields(): ICardSortField[] {
        const widgets: ICardWidget<IOrder>[] = [];

        if (this.cardHeader) {
            widgets.push(this.cardHeader);
        }

        widgets.push(...this.cardWidgets);

        if (this.cardFooter) {
            widgets.push(this.cardFooter);
        }

        const fields = widgets.filter(w => w.sortable).map(w => ({ id: w.id, text: w.name } as ICardSortField));

        for (const field of fields) {
            const sortField = this.table.settings.sort?.find((s: ITableSort) => s.colId === field.id);

            if (sortField) {
                field.direction = sortField.sort;
            }
        }

        return fields;
    }

    private get cardMoreFunc(): CardMoreFunc<IOrder> {
        return OrderCards.getCardMoreFunc(this.cardListContext);
    }

    private get can(): any {
        const secure = this.$secure;
        const offices = this.selectedOffices;
        return {
            get update(): Function {
                return (order: IOrder): boolean => {
                    return secure.check(PermissionType.Offices, order.office, PermissionRight.OrdersUpdate);
                };
            },
            get delete(): Function {
                return (order: IOrder): boolean => {
                    return secure.check(PermissionType.Offices, order.office, PermissionRight.OrdersDelete);
                };
            },
            get editOrderType(): boolean {
                return secure.checkCommon(PermissionCommonSection.Lists, PermissionRight.Update);
            },
            get createOffice(): boolean {
                return secure.checkCommon(PermissionCommonSection.Offices, PermissionRight.Create);
            },
            get create(): boolean {
                for (const office of offices) {
                    const valid = secure.check(PermissionType.Offices, office.id, PermissionRight.OrdersCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    @Watch("selectedOffices", { deep: true, immediate: true })
    private onSelectedOfficesUpdate(): void {
        const headerControls = this.$info.ui.getHeaderControls();

        const hdrOffices = headerControls.find(c => c.id === "orders.header-offices") as MultiDropdown<IOffice>;
        if (hdrOffices) {
            if (!this.$alt.compareArrays(hdrOffices.selectedItems, this.selectedOffices, e => e.id)) {
                hdrOffices.selectedItems = this.selectedOffices;
            }
        }
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.offices = await this.$info.getOffices();
            this.stores = await this.$info.getStores();
            this.accounts = await this.$info.getAccounts();
            this.employees = await this.$info.getEmployees();
            this.plugins = await this.$info.getPlugins();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async initData(): Promise<void> {
        await Promise.all([
            this.selectTableSettings(),
            //this.selectFormSettings(),
            this.selectOrderStages(),
            this.selectOrderTypes(),
            this.selectClientSources(),
            this.selectProductTypes(),
            this.selectDocuments(),
            this.selectSmss(),
        ]);

        const filterOffices = this.filter.filter(item => item.field === "office");
        this.selectedOffices = this.offices.filter(o => !!filterOffices.find(f => f.value === o.id));

        this.filterController.init(this.filterContext);

        if (!this.selectedOffices.length && this.offices[0]) {
            this.filterController.filter.office.set([this.offices[0].id]);

            this.saveFilter(this.filterController.tableFilter);
        }

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrOffices = new MultiDropdown<IOffice>();
        hdrOffices.id = "orders.header-offices";
        hdrOffices.items = this.offices;
        hdrOffices.selectedItems = this.selectedOffices;
        hdrOffices.itemId = item => item.id;
        hdrOffices.itemName = item => item.info.name;
        hdrOffices.iconPackage = "alt";
        hdrOffices.icon = "houses";
        hdrOffices.locale = {
            Tooltip: "Выбрать филиал",
            ButtonSelectOne: "Выбрать один",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Филиал не выбран",
            TextSelectedAll: "Все филиалы",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["филиал", "филиала", "филиалов"],
        };
        hdrOffices.addChangedHandler((s, e) => {
            this.filterController.filter.office.set(e.items.map(account => account.id));
            this.saveFilter(this.filterController.tableFilter);
        });

        const hdrRefresh = new Button();
        hdrRefresh.id = "orders.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrOffices, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new !== undefined) {
                await this.openFormCreate();
                return;
            }

            if (this.$route.query.id) {
                const orderId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const order = await this.getOrder(orderId);
                this.openFormUpdate(order);
            }

            if (this.$route.query.welcome !== undefined) {
                this.openWelcomeModal();
            }

            // if (typeof this.$route.query.notification === "string") {
            //     this.notificationShow = true;
            //     //this.$router.push({ query: undefined }).catch(() => {});
            // }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw new Error("Заявка не найдена.");
        }
    }

    private async showOrderStageModal(order: IOrder): Promise<void> {
        if (!order.officeRef) {
            return;
        }

        const orderType = this.orderTypes.find(t => t.id === order.type);
        if (!orderType) {
            return;
        }

        const documents = await this.$settings.getDocumentsOrderComplete();
        const sendSmsStatus = await this.$settings.getOrderStageSendSmsStatus();
        const documentsSelected = this.documents.filter(d => documents.includes(d.id));

        const printContext: OrderPrintContext = {
            company: this.company,
            office: order.officeRef,
            order,
            orderType,
            productTypes: this.productTypes,
        };

        const completeModalContext = {
            order: order,
            stages: this.orderStagesData,
            accounts: this.accounts,
            documents: this.documents,
            documentsSelected: documentsSelected,
        };

        const context = {
            order: order,
            stages: this.orderStagesData,
            plugins: this.plugins,
            isSendSmsSelected: sendSmsStatus,
            templates: this.smss,
            printContext,
            completeModal: this.completeModal,
            completeModalContext: completeModalContext,
        };

        await this.orderStageModal.show(context);
    }

    private async complete(
        order: IOrder,
        stage: IOrderStage,
        infoDto?: IOrderInfoChangeDto,
        paymentDto?: IOrderCreatePaymentDto,
        documents?: ITemplateDocument[],
    ): Promise<boolean> {
        try {
            if (!order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const usecase = this.$alt.system.usecase.createOrderUseCase();
            const completedOrder = await usecase.complete(
                order.company as string,
                order.id,
                stage.id,
                infoDto,
                undefined,
                paymentDto,
            );

            this.$alt.toast.success("Заявка успешно закрыта.");
            this.saveSelectedDocuments(documents ?? []);
            this.print(documents ?? [], order);
            this.changeOrder(completedOrder, order);
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось завершить заявку:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private print(documents: ITemplateDocument[], order: IOrder): void {
        if (!order) {
            throw new AppException("Заявка не задана.");
        }

        if (!order.officeRef) {
            return;
        }

        const orderType = this.orderTypes.find(t => t.id === order.type);

        if (!orderType) {
            return;
        }

        const context: OrderPrintContext = {
            company: this.company,
            office: order.officeRef,
            order: order,
            orderType: orderType,
            productTypes: this.productTypes,
        };

        Printer.printOrderDocuments(documents, context);
    }

    private async saveSelectedDocuments(documents: ITemplateDocument[]): Promise<void> {
        try {
            const value = documents.map(d => d.id);
            await this.$settings.setDocumentsOrderComplete(value);
        } catch {}
    }

    private async saveSendSmsStatus(status: boolean): Promise<void> {
        try {
            await this.$settings.setOrderStageSendSmsStatus(status);
        } catch {}
    }

    private async openWelcomeModal(): Promise<void> {
        this.welcomeModal.onModalHide = () => this.$router.push({ query: undefined }).catch(() => {});

        const user = await this.$info.getUser();
        const context = {
            user: user,
            router: this.$router,
        };
        await this.welcomeModal.show(context);
    }

    private async openFormCreate(): Promise<void> {
        const result = await this.$alt.system.usecase.createCompanyPricingUseCase().checkCreateOrder(this.company.id);
        if (!result) {
            const company = await this.$alt.system.usecase.createCompanyUseCase().get(this.company.id);
            company ? this.$info.setCompany(company) : undefined;
            this.needPaymentShow = true;
            return;
        }

        this.formOrderCreate.onOpening = async () => this.$router.push({ query: { new: "" } }).catch(() => {});
        this.formOrderCreate.onClosed = async () => this.$router.push({ query: undefined }).catch(_ => {});
        this.formOrderCreate.openCreate();
    }

    private async openFormUpdate(order: IOrder): Promise<void> {
        this.formOrderView.onOpening = async () => this.$router.push({ query: { id: order.id } }).catch(() => {});
        this.formOrderView.onClosed = async () => this.$router.push({ query: undefined }).catch(_ => {});
        this.formOrderView.open(order);
    }

    public showFilter(): void {
        this.filterController.show(this.filterContext);
    }

    private async saveFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            const dto: ISettingsTableUpdateDto = { filter };
            const service = this.$alt.system.usecase.createSettingsTableUseCase();
            await service.update(this.company.id, this.user.id, TableType.Order, dto);

            this.changeFilter(filter);

            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось сохранить настройки фильтра:\n${e.message}`);
            return false;
        }
    }

    private async confirmDelete(order: IOrder): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить заявку №${order.number}?`,
            "Удаление заявки",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(order);
        }
    }

    private async confirmDeleteMany(orders: IOrder[]): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ${orders.length} заявок?`,
            "Удаление заявок",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.deleteMany(orders);
        }
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async changeFilter(filter: ITableFilter[], withDataUpdate = true): Promise<void> {
        try {
            this.table.settings.filter = filter;

            const filterOffices = this.filter.filter(item => item.field === "office");
            this.selectedOffices = this.offices.filter(o => !!filterOffices.find(f => f.value === o.id));

            if (withDataUpdate) {
                await this.selectData(this.skip, this.limit, this.sort, this.filter);
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    /*protected async selectFormSettings(): Promise<void> {
        try {
            this.form.settings = await this.CompanySettings.formGet(this.company.id, this.formType);
        } catch {}
    }

    protected async saveFormSettings(settings: any): Promise<void> {
        try {
            this.form.settings = await this.CompanySettings.formUpdate(
                this.company.id,
                this.formType,
                { fields: settings.fields }
            );
            this.toast.success("Настройки формы сохранены.");
        } catch (e: any) {
            this.toast.error(`Не удалось сохранить настройки формы:\n${e.message}`);
        }
    }*/

    protected async selectTableSettings(): Promise<void> {
        let settings: ITable | null = null;
        try {
            settings = await this.SettingsTable.get(this.company.id, this.user.id, this.tableType);
        } catch {}
        this.applyTableSettings(settings ?? this.table.settings);
    }

    private applyTableSettings(settings: ITable | null): void {
        if (!settings) {
            throw new Error();
        }

        this.table.settings = settings;

        if (settings.filter && settings.filter.length > 0) {
            return;
        }
    }

    protected async changeTableColumns(columns: ITableColumn[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    protected async changeCardSettings(cards: ITableCardSettings): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { cards };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }

    protected async sortData(sort: ITableSort[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    protected async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    protected async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            // добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async selectOrderStages(): Promise<void> {
        try {
            // TODO
            //this.stages = await this.$alt.system.usecase.createOrderStageUseCase().selectForOffice(this.company.id, this.officeId);
            this.stages = await this.$alt.system.usecase.createOrderStageUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить этапы:\n${e.message}`);
        }
    }

    private async selectOrderTypes(): Promise<void> {
        try {
            // TODO
            //this.orderTypes = await this.$alt.system.usecase.createOrderTypeUseCase().selectForOffice(this.company.id, this.officeId);
            this.orderTypes = await this.$alt.system.usecase.createOrderTypeUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить типы заявок:\n${e.message}`);
        }
    }

    private async selectClientSources(): Promise<void> {
        try {
            this.clientSources = await this.$alt.system.usecase.createClientSourceUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить источники килентов:\n${e.message}`);
        }
    }

    private async selectProductTypes(): Promise<void> {
        try {
            this.productTypes = await this.$alt.system.usecase.createProductTypeUseCase().select(this.company.id);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить типы продуктов:\n${e.message}`);
        }
    }

    private async selectDocuments(): Promise<void> {
        try {
            this.documents = await this.$alt.system.usecase
                .createTemplateDocumentUseCase()
                .select(this.company.id, TemplateDocumentType.OfficeGeneral);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить документы:\n${e.message}`);
        }
    }

    private async selectSmss(): Promise<void> {
        try {
            this.smss = await this.$alt.system.usecase
                .createTemplateNotificationUseCase()
                .select(this.company.id, TemplateNotificationType.SmsGeneral);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить СМС:\n${e.message}`);
        }
    }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: IReadQuery = { limit, offset, sort, search, filter };
            const result = await this.OrderUseCase.select(this.company.id, query);

            this.orders = result.data;
            this.ordersTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить заявки:\n${e.message}`);
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.orders = [];
            this.ordersTotal = 0;
        }
    }

    private async changeStage(
        orig: IOrder,
        stage: IOrderStage,
        comment: string,
        bindingId?: string,
        notification?: IPluginNotificationSendDto,
    ): Promise<IOrder | null> {
        try {
            this.$alt.loader.show();
            const order = await this.OrderUseCase.changeStage(this.company.id, orig.id, stage.id, comment);
            if (stage.type === OrderStageType.Ready) {
                if (bindingId && notification) {
                    await this.sendSms(order.id, bindingId, notification);
                    await this.saveSendSmsStatus(true);
                } else {
                    await this.saveSendSmsStatus(false);
                }
            }

            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            return order;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось обновить статус заявки:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async sendSms(orderId: string, bindingId: string, dto: IPluginNotificationSendDto): Promise<void> {
        try {
            if (this.plugins.length === 0) {
                this.$alt.toast.error("Интеграция с сервисом уведомлений отсутствует.");
                return;
            }

            this.$alt.loader.show();

            const result = await this.$alt.system.usecase
                .createOrderUseCase()
                .sendNotification(this.company.id, orderId, bindingId, dto);

            if (result.result.cost < 0) {
                this.$alt.toast.error("Не удалось отправить сообщение.");
                return;
            }

            this.$info.setBalance(result.result.balance);
            this.$alt.toast.success(`Сообщение отправлено. Цена: ${result.result.cost}`);
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось отправить сообщение:\n${e.message}`);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async getOrder(id: string): Promise<any> {
        try {
            const order = await this.OrderUseCase.get(this.company.id, id);
            if (!order) {
                throw new Error("Заявка не найдена.");
            }
            return order;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные заявки:\n${e.message}`);
        }
    }

    private async create(
        orderDto: IOrderCreateDto,
        clientDto?: IClientCreateDto,
        paymentDto?: IOrderCreatePaymentDto,
    ): Promise<IOrder | null> {
        let orderCreated: IOrder | null = null;
        try {
            this.$alt.loader.show();
            orderCreated = await this.OrderUseCase.create(this.company.id, orderDto, clientDto, paymentDto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Заявка успешно создана.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return orderCreated;
        } finally {
            this.$alt.loader.hide();
        }
        return orderCreated;
    }

    private async delete(order: IOrder): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.OrderUseCase.delete(this.company.id, order.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Заявка успешно удалёна.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async deleteMany(orders: IOrder[]): Promise<void> {
        try {
            this.$alt.loader.show();
            const dto: IOrderDeleteManyDto = {
                ids: orders.map(o => o.id),
            };
            await this.OrderUseCase.deleteMany(this.company.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Заявки успешно удалены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async searchClients(search: string): Promise<IClient[]> {
        try {
            const limit: number = 10;
            const query: IReadQuery = { search, limit, offset: 0 };
            const result = await this.ClientUseCase.select(this.company.id, query);
            return result.data;
        } catch (e: any) {
            return [];
        }
    }

    private async changeOrder(order: IOrder, orderOld: IOrder): Promise<void> {
        this.formOrderView.order = order;

        const index = this.orders.findIndex(o => o.id === orderOld.id);
        if (index >= 0) {
            this.orders[index] = order;
            this.table.update();
        }
    }

    private async saveTableSettings(dto: ISettingsTableUpdateDto): Promise<void> {
        try {
            const settings = await this.SettingsTable.update(this.company.id, this.user.id, this.tableType, dto);
            this.applyTableSettings(settings);
        } catch {}
    }
}
