import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton, BTableSimple, BThead, BTbody, BTfoot, BTr, BTh, BTd, VBTooltip } from "bootstrap-vue";
import {
    DiscountType,
    IAccount,
    IOrder,
    IOrderMaterial,
    IOrderMaterialCreateDto,
    IOrderMaterialUpdateDto,
    IOrderMaterialGetDto,
    IOrderWork,
    IStore,
    PaymentType,
    IGood,
    IOrderWorkUpdateDto,
    IOrderWorkCreateDto,
    IWork,
    IWarranty,
    Locale,
    Currency,
    PermissionType,
    PermissionRight,
    IReadQuery,
} from "@lib";
import { ModalComponent } from "@core/components/alt-ui/modal";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { Html, Icon, Label, Link, List, Panel } from "@core/components/alt-ui/controls";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import { WorkModal } from "./work.modal";
import { MaterialModal } from "./material.modal";
import { MaterialGetModal } from "./material-get.modal";
import { Formatter } from "@/utils/formatter";
import { MaterialModal2 } from "./material.modal2";

interface IWorkMaterialItem {
    type: "work" | "material";
    name: string;
    price: number;
    quantity: number;
    warranty: IWarranty;
    store?: string;
    good?: string;
    employee?: string;
    tag: IOrderWork | IOrderMaterial;
    subitem?: boolean;
}

@Component({
    name: "order-view-wnm",
    components: {
        BButton,
        BTableSimple,
        BThead,
        BTbody,
        BTfoot,
        BTr,
        BTh,
        BTd,
        ModalComponent,
        ControlComponent,
    },
    directives: { "b-tooltip": VBTooltip },
    filters: { warranty: WarrantyUtils.toString },
})
export default class OrderViewWnm extends Vue {
    // @Prop({ type: Array, required: true })
    // private value!: IOrderWork[];

    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ type: Array, required: true })
    private works!: IOrderWork[];

    @Prop({ type: Array, required: true })
    private materials!: IOrderMaterial[];

    @Prop({ type: Array, default: () => [] })
    private employees!: any[];

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ type: String, default: "default" })
    private size!: string;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private OrderWorkUseCase = this.$alt.system.usecase.createOrderWorkUseCase();
    private OrderMaterialUseCase = this.$alt.system.usecase.createOrderMaterialUseCase();

    private tableWnM: List<IWorkMaterialItem>;

    private workModal: WorkModal;
    private materialModal: MaterialModal;
    private materialGetModal: MaterialGetModal;
    private materialModal2: MaterialModal2;

    constructor() {
        super();

        this.workModal = new WorkModal();
        this.workModal.onCreate = this.createWork.bind(this);
        this.workModal.onUpdate = this.updateWork.bind(this);

        this.materialModal = new MaterialModal();
        this.materialModal.onCreate = this.createMaterial.bind(this);
        this.materialModal.onUpdate = this.updateMaterial.bind(this);

        this.materialGetModal = new MaterialGetModal();
        this.materialGetModal.onGet = this.getMaterial.bind(this);

        this.materialModal2 = new MaterialModal2();
        this.materialModal2.onGet = this.getMaterial.bind(this);
        this.materialModal2.onCreate = this.createMaterial.bind(this);
        this.materialModal2.onUpdate = this.updateMaterial.bind(this);

        this.tableWnM = new List<IWorkMaterialItem>();
    }

    private worksSynced: IOrderWork[] = [];
    private materialsSynced: IOrderMaterial[] = [];

    private get currency(): Currency | undefined {
        return this.order?.officeRef?.info?.currency;
    }

    private get locale(): Locale | undefined {
        return this.order?.officeRef?.info?.locale;
    }

    private get items(): IWorkMaterialItem[] {
        const items: IWorkMaterialItem[] = [];

        for (const work of this.worksSynced) {
            const item = this.makeItemByWork(work);
            items.push(item);

            const subitems = this.getWorkMaterials(work);
            items.push(...subitems);
        }

        for (const material of this.materialsSynced) {
            if (material.work) {
                continue;
            }

            const item = this.makeItemByMaterial(material);
            items.push(item);
        }

        return items;
    }

    private get resultCost(): number {
        let cost = 0.0;
        for (const item of this.items) {
            cost += item.price * item.quantity;
        }
        return cost;
    }

    private get discountText(): string {
        if (!this.order?.info?.discount) {
            return "";
        }

        switch (this.order.info.discount.type) {
            case DiscountType.Fixed:
                return ""; //moneyFormat(this.order.info.discount.value, { locale: this.locale, currency: this.currency });
            case DiscountType.Percent:
                return this.order.info.discount.value + "%";
        }

        return "";
    }

    private get totalDiscount(): number {
        if (!this.order?.info?.discount) {
            return 0.0;
        }

        const discount = this.order.info.discount.value ?? 0.0;
        switch (this.order.info.discount.type) {
            case DiscountType.Fixed:
                return discount;
            case DiscountType.Percent:
                return (this.resultCost * discount) / 100;
        }

        return 0.0;
    }

    private get prepayment(): number {
        if (!this.order || !this.order.payments) {
            return 0.0;
        }

        let sum = 0.0;
        for (const payment of this.order.payments) {
            if (payment.type === PaymentType.Prepayment) {
                sum += payment.value;
            }
        }
        return sum;
    }

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private get can(): any {
        const secure = this.$secure;

        return {
            readGoods(storeId: string): boolean {
                return secure.check(PermissionType.Stores, storeId, PermissionRight.GoodsRead);
            },
            getGoods(storeId: string): boolean {
                return secure.check(PermissionType.Stores, storeId, PermissionRight.GoodsAsOrderMaterials);
            },
        };
    }

    private get availableStores(): IStore[] {
        return this.stores.filter(s => this.can.readGoods(s.id) && this.can.getGoods(s.id));
    }

    @Watch("works")
    private onWorksInChanged(): void {
        this.initValues();
    }

    @Watch("materials")
    private onMaterialsInChanged(): void {
        this.initValues();
    }

    public mounted(): void {
        this.initValues();
    }

    private initValues(): void {
        //console.log(this.value);
        this.worksSynced = this.$alt.clone(this.works);
        this.materialsSynced = this.$alt.clone(this.materials);

        this.initListControl();
    }

    private initListControl(): void {
        this.tableWnM = new List<IWorkMaterialItem>();
        this.tableWnM.items = this.items;
        this.tableWnM.classItem = i => (i.subitem ? "pl-3.5" : "");
        this.tableWnM.left = i => {
            const icon = new Icon();
            icon.class = "mr-0.5";
            if (i.subitem) {
                icon.icon = "LinkIcon";
            } else {
                icon.icon = i.type === "work" ? "ToolIcon" : "GridIcon";
            }
            return icon;
        };
        this.tableWnM.header = i => i.name.toUpperCase();
        this.tableWnM.content = i => {
            const employeePart = i.employee ? `Исполнитель: ${i.employee}<br/>` : "";
            const warrantyPart = i.warranty ? `Гарантия: ${WarrantyUtils.toString(i.warranty)}<br/>` : "";

            const html = new Html();
            html.html = `
                ${employeePart}
                ${warrantyPart}
                Цена: ${Formatter.money(i.price, { locale: this.locale, currency: this.currency })}<br/>
                Количество: ${Formatter.number(i.quantity, { locale: this.locale })}<br/>
                Стоимость: <strong>${Formatter.money(i.quantity * i.price, {
                    locale: this.locale,
                    currency: this.currency,
                })}</strong>
            `;
            html.class = "my-0.5";
            return html;
        };
        this.tableWnM.footer = i => {
            const linkEdit = new Link();
            linkEdit.text = "Редактировать";
            linkEdit.click = () => this.showItemUpdateModal(i);
            linkEdit.visible = i.type === "work" ? !this.readonly : !this.readonly && !i.store && !i.good;

            const linkDelete = new Link();
            linkDelete.text = "Удалить";
            linkDelete.click = () => this.confirmItemDelete(i);
            linkDelete.visible = !this.readonly;

            const separator = new Label();
            separator.text = "|";
            separator.style = "margin: 0rem 0.5rem !important";
            separator.visible = i.type === "work" ? !this.readonly : !this.readonly && !i.store && !i.good;

            const pnl = new Panel();
            pnl.class = "flex";
            pnl.addControls([linkEdit, separator, linkDelete]);
            pnl.visible = !this.readonly;
            return pnl;
        };
        // this.tableWnM.tools = i => {
        //     const btnEdit = new Button();
        //     btnEdit.text = "1";
        //     btnEdit.variant = "outline-primary";

        //     const btnDelete = new Button();
        //     btnDelete.text = "2";
        //     btnDelete.variant = "outline-danger";

        //     return [btnEdit, btnDelete];
        // };
    }

    private getWorkMaterials(work: IOrderWork): any[] {
        const items: any[] = [];

        for (const material of this.materialsSynced) {
            if (material.work !== work.id) {
                continue;
            }

            const item = this.makeItemByMaterial(material, true);
            items.push(item);
        }

        return items;
    }

    private makeItemByWork(work: IOrderWork): any {
        return {
            type: "work",
            name: work.name,
            price: work.price,
            quantity: work.quantity,
            warranty: work.warranty,
            employee: work.employeeRef?.info?.name ?? "",
            tag: work,
        };
    }

    private makeItemByMaterial(material: IOrderMaterial, subitem: boolean = false): any {
        return {
            subitem: subitem,

            type: "material",
            name: material.name,
            price: material.price,
            quantity: material.quantity,
            warranty: material.warranty,
            store: material.store,
            good: material.good,
            tag: material,
        };
    }

    private onOrderChanged(order: IOrder): void {
        this.$emit("order-changed", order, this.order);
    }

    //

    public async showWorkCreateModal(): Promise<void> {
        const user = await this.$info.getUser();

        const context = {
            employees: this.employees,
            user: user,
            accounts: this.accounts,
            readonly: this.readonly,
            searchWorks: this.searchWorks.bind(this),
        };
        await this.workModal.show(context);
    }

    public async showItemUpdateModal(item: IWorkMaterialItem): Promise<void> {
        if (item.type === "work" && !this.readonly) {
            this.showWorkUpdateModal(item.tag as IOrderWork);
            return;
        }

        if (item.type === "material" && !item.store && !item.good && !this.readonly) {
            this.showMaterialUpdateModal(item.tag as IOrderMaterial);
            return;
        }
    }

    public async confirmItemDelete(item: IWorkMaterialItem): Promise<void> {
        if (item.type === "work" && !this.readonly) {
            this.confirmWorkDelete(item.tag as IOrderWork);
            return;
        }

        if (item.type === "material" && !item.store && !item.good && !this.readonly) {
            this.confirmMaterialDelete(item.tag as IOrderMaterial);
            return;
        }
    }

    public async showWorkUpdateModal(work: IOrderWork): Promise<void> {
        const user = await this.$info.getUser();

        const context = {
            work: work,
            employees: this.employees,
            user: user,
            accounts: this.accounts,
            readonly: this.readonly,
            searchWorks: this.searchWorks.bind(this),
        };
        await this.workModal.show(context);
    }

    public async confirmWorkDelete(work: IOrderWork): Promise<void> {
        const confirm = await this.$alt.message.confirm(`Удалить работу "${work.name}"?`, "Удаление работы", {
            acceptText: "Удалить",
        });

        if (confirm) {
            await this.deleteWork(work);
        }
    }

    public async showMaterialModal(): Promise<void> {
        const settings = await this.$settings.getOrderMaterialModal();

        const context = {
            accounts: this.accounts,
            stores: this.availableStores,
            works: this.works,
            readonly: this.readonly,
            settings,
            searchGoods: this.searchMaterials2.bind(this),
        };

        await this.materialModal2.show(context);
    }

    public async showMaterialCreateModal(): Promise<void> {
        const settings = await this.$settings.getOrderMaterialModal();

        const context = {
            accounts: this.accounts,
            stores: this.availableStores,
            works: this.works,
            readonly: this.readonly,
            settings,
        };
        await this.materialModal.show(context);
    }

    public async showMaterialUpdateModal(material: IOrderMaterial): Promise<void> {
        const context = {
            material: material,
            accounts: this.accounts,
            stores: this.availableStores,
            works: this.works,
            readonly: this.readonly,
        };
        await this.materialModal2.show(context);
    }

    public async showMaterialGetModal(): Promise<void> {
        const context = {
            stores: this.availableStores,
            works: this.works,
            searchGoods: this.searchMaterials.bind(this),
        };
        await this.materialGetModal.show(context);
    }

    public async confirmMaterialDelete(material: IOrderMaterial): Promise<void> {
        const text =
            material.store && material.good
                ? `Вернуть материал "${material.name}" на склад?`
                : `Удалить материал "${material.name}"?`;

        const confirm = await this.$alt.message.confirm(text, "Удаление материала", { acceptText: "Удалить" });
        if (confirm) {
            await this.deleteMaterial(material);
        }
    }

    // work

    private async createWork(dto: IOrderWorkCreateDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const compantId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderWorkUseCase.create(compantId, officeId, this.order.id, dto);
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось добавить работу.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async updateWork(orig: IOrderWork, dto: IOrderWorkUpdateDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const compantId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderWorkUseCase.update(compantId, officeId, this.order.id, orig.id, dto);
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось изменить работу.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async searchWorks(search: string): Promise<IWork[]> {
        try {
            const company = await this.$info.getCompany();
            const query: IReadQuery = { search };
            const result = await this.$alt.system.usecase.createWorkUseCase().select(company.id, query);
            return result.data;
        } catch {
            return [];
        }
    }

    private async deleteWork(work: IOrderWork): Promise<void> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderWorkUseCase.delete(companyId, officeId, this.order.id, work.id);
            this.onOrderChanged(order);
        } catch {
            this.$alt.toast.error("Не удалось удалить работу.");
        } finally {
            this.$alt.loader.hide();
        }
    }

    // material

    private async createMaterial(dto: IOrderMaterialCreateDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const compantId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderMaterialUseCase.create(compantId, officeId, this.order.id, dto);
            this.onOrderChanged(order);
            this.$settings.setOrderMaterialModal({
                accountWidthdrawal: !!dto.payment?.account,
                accountId: dto.payment?.account,
                storeAddition: !!dto.store,
                storeId: dto.store,
            });
            return true;
        } catch {
            this.$alt.toast.error("Не удалось добавить материал.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async updateMaterial(orig: IOrderMaterial, dto: IOrderMaterialUpdateDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const compantId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderMaterialUseCase.update(compantId, officeId, this.order.id, orig.id, dto);
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось изменить материал.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async getMaterial(dto: IOrderMaterialGetDto): Promise<boolean> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const compantId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderMaterialUseCase.getFromStore(compantId, officeId, this.order.id, dto);
            this.onOrderChanged(order);
            return true;
        } catch {
            this.$alt.toast.error("Не удалось добавить материал.");
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async searchMaterials(store: IStore, search: string): Promise<IGood[]> {
        try {
            const companyId = store.company;
            const query: IReadQuery = {
                search,
                limit: 10,
                offset: 0,
                filter: {
                    quantity: { gt: 0 },
                },
            };
            const result = await this.$alt.system.usecase
                .createGoodUseCase()
                .selectForStore(companyId, store.id, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private async searchMaterials2(search: string): Promise<IGood[]> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            const companyId = this.order.company;
            const query: IReadQuery = {
                search,
                limit: 10,
                offset: 0,
                filter: {
                    quantity: { gt: 0 },
                    store: { in: this.availableStores.map(store => store.id) },
                },
            };
            const result = await this.$alt.system.usecase.createGoodUseCase().select(companyId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private async deleteMaterial(material: IOrderMaterial): Promise<void> {
        try {
            if (!this.order) {
                throw new Error("Заявка не задана.");
            }

            this.$alt.loader.show();

            const companyId = this.order.company;
            const officeId = this.order.office;
            const order = await this.OrderMaterialUseCase.delete(companyId, officeId, this.order.id, material.id);
            this.onOrderChanged(order);
        } catch {
            this.$alt.toast.error("Не удалось удалить материал.");
        } finally {
            this.$alt.loader.hide();
        }
    }
}
