import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import {
    IAccount,
    IOrder,
    IOrderPaymentCreateDto,
    IOrderType,
    IOrderTypeField,
    IOrderTypeFieldGroup,
    IPayment,
    OrderTypeFieldGroupType,
} from "@lib";
import AltIcon from "@/core/components/alt-icon";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { OrderPaymentModal } from "./order-view-tabs/order-payment.modal";

@Component({
    components: {
        BButton,
        ValidationObserver,
        ModalComponent,
        AltIcon,
    },
})
export default class OrderViewPayments extends Vue {
    @Prop({ required: true })
    private order!: IOrder | null;

    @Prop({ required: true })
    private orderType!: IOrderType;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    private orderPaymentModal!: OrderPaymentModal;

    constructor() {
        super();

        this.orderPaymentModal = new OrderPaymentModal("order-payment-modal-2");
        this.orderPaymentModal.onCreate = this.createPayment.bind(this);
    }

    private visible = true;
    private editMode = false;
    private fieldGroup: IOrderTypeFieldGroup | null = null;

    private get title(): string {
        return this.fieldGroup?.name ?? "Оплата";
    }

    private get icon(): string {
        return this.fieldGroup?.icon ?? "bank-card";
    }

    private get total(): number {
        if (!this.order || !this.order.payments) {
            return 0.0;
        }

        let sum = 0.0;
        for (const payment of this.order.payments) {
            sum += payment.value;
        }
        return sum;
    }

    private get currency(): string | undefined {
        return this.order?.officeRef?.info?.currency;
    }

    private get locale(): string | undefined {
        return this.order?.officeRef?.info?.locale;
    }

    private get payments(): IPayment[] {
        if (!this.order || !this.order.payments) {
            return [];
        }

        return this.order.payments;
    }

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    @Watch("orderType", { immediate: true, deep: true })
    private onOrderTypeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        if (!this.orderType) {
            return;
        }

        this.editMode = false;
        this.fieldGroup = this.orderType.groups.find(g => g.type === OrderTypeFieldGroupType.Payment) ?? null;
        this.visible = this.getFields().length > 0;
    }

    public getFields(): IOrderTypeField[] {
        return (
            this.fieldGroup?.fields
                .filter(f => !f.hidden)
                .sort((a, b) =>
                    a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
                ) ?? []
        );
    }

    public async showCreateModal(): Promise<void> {
        const user = await this.$info.getUser();
        await this.orderPaymentModal.show({
            accounts: this.accounts,
            userId: user.id,
        });
    }

    private async createPayment(dto: IOrderPaymentCreateDto): Promise<IOrder> {
        const companyId = this.order?.company as string;
        const officeId = this.order?.office as string;
        const orderId = this.order?.id as string;

        const usecase = this.$alt.system.usecase.createOrderPaymentUseCase();
        const order = await usecase.create(companyId, officeId, orderId, dto);

        this.$emit("order-changed", order, this.order);

        return order;
    }

    private startEditInfo(): void {
        this.editMode = true;
    }

    private async applyEditInfo(): Promise<void> {
        this.editMode = false;
    }

    private cancelEditInfo(): void {
        this.editMode = false;
    }
}
