import {
    DiscountType,
    IAccount,
    IDiscount,
    ITemplateDocument,
    IOrder,
    IOrderCreatePaymentDto,
    IOrderInfoChangeDto,
    IOrderStage,
    Locale,
    OrderStageType,
} from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    CheckBoxSelect,
    CheckBoxSelectChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    Select,
    TextBox,
    TextChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { ValueChangedEventArgs } from "@core/components/alt-ui";
import { DiscountBox } from "@core/controls/discount-box";
import { Localizer } from "@/i18n/localizer";
import { StringUtils } from "@/utils/string.utils";

export interface IOrderViewCompleteModalContext {
    order: IOrder;
    stages: IOrderStage[];
    accounts: IAccount[];
    documents: ITemplateDocument[];
    documentsSelected: ITemplateDocument[];
}

export class OrderViewCompleteModal extends Modal<IOrderViewCompleteModalContext> {
    private cbStage!: Select<IOrderStage>;
    private tbActualPrice!: TextBox;
    private dbDiscount!: DiscountBox;
    private tbPaidSum!: TextBox;
    private tbTotalToPay!: TextBox;
    private cbAccount!: Select<IAccount>;
    private cbsPrint!: CheckBoxSelect<ITemplateDocument>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: IOrderViewCompleteModalContext | null = null;
    public onComplete:
        | ((
              order: IOrder,
              stage: IOrderStage,
              infoDto?: IOrderInfoChangeDto,
              paymentDto?: IOrderCreatePaymentDto,
              documents?: ITemplateDocument[],
          ) => Promise<boolean>)
        | null = null;

    private paymentsSum = 0.0;
    private actualPrice = 0.0;
    private discount: IDiscount = { value: 0.0, type: DiscountType.Percent };
    private total = 0.0;

    public constructor() {
        super("order-view-complete-modal", "Завершение заявки");
        this.initializeControls();
    }

    public show(context: IOrderViewCompleteModalContext): Promise<void> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStage = new Select<IOrderStage>();
        this.cbStage.id = "order-view-complete.stage";
        this.cbStage.label = "Перевести заявку на этап";
        this.cbStage.iconPackage = IconPackage.Feater;
        this.cbStage.icon = "CheckCircleIcon";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = s => s.name;
        this.cbStage.descriptionField = a => a.description;

        this.tbActualPrice = new TextBox();
        this.tbActualPrice.id = "order-view-complete.actual-price";
        this.tbActualPrice.label = "Фактическая цена";
        //this.tbActualPrice.validation = "required|money|unsigned";
        this.tbActualPrice.disabled = true;

        this.dbDiscount = new DiscountBox();
        this.dbDiscount.id = "order-view-complete.discount";
        this.dbDiscount.label = "Скидка";
        this.dbDiscount.addValueChangedHandler(this.changeDiscount.bind(this));

        this.tbPaidSum = new TextBox();
        this.tbPaidSum.id = "order-view-complete.paid";
        this.tbPaidSum.label = "Оплачено";
        this.tbPaidSum.disabled = true;

        this.tbTotalToPay = new TextBox();
        this.tbTotalToPay.id = "order-view-complete.total";
        this.tbTotalToPay.label = "Сумма к оплате";
        this.tbTotalToPay.validation = "required|money|unsigned";
        this.tbTotalToPay.addTextChangedHandler(this.changeTotalPay.bind(this));

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = "order-view-complete.account";
        this.cbAccount.label = "Счёт";
        this.cbAccount.iconPackage = IconPackage.Alt;
        this.cbAccount.icon = "finance";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = a => a.info.name;
        this.cbAccount.descriptionField = a => a.info.description;
        this.cbAccount.selectedIndex = this.cbAccount.items.length > 0 ? 0 : -1;

        this.cbsPrint = new CheckBoxSelect<ITemplateDocument>();
        this.cbsPrint.id = "order-view-complete.print";
        this.cbsPrint.class = "w-full";
        this.cbsPrint.items = this.context?.documents ?? [];
        this.cbsPrint.textField = d => d.name;
        this.cbsPrint.addChangedHandler(this.changeDocuments.bind(this));
        this.cbsPrint.selectedItems = this.context?.documentsSelected ?? [];

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "order-view-complete.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "order-view-complete.save";
        this.btnSave.text = "Завершить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(context: IOrderViewCompleteModalContext): void {
        const index = this.cbStage.items.findIndex(s => s.type === OrderStageType.Closed) ?? -1;
        this.cbStage.selectedIndex = index >= 0 ? index : 0;

        this.paymentsSum = this.getPaymentsSum();
        this.actualPrice = this.getWorksSum() + this.getMaterailsSum();
        this.discount = this.context?.order?.info?.discount
            ? this.context.order.info.discount
            : { value: 0.0, type: DiscountType.Percent };
        this.total = 0.0;
        this.calculateTotal();

        // TODO: брать локаль из филиала
        const locale = Locale.RU;

        this.tbPaidSum.text = Localizer.money(this.paymentsSum, locale);
        this.tbActualPrice.text = Localizer.money(this.actualPrice, locale);
        this.tbTotalToPay.text = Localizer.money(this.total, locale);
    }

    private getPaymentsSum(): number {
        if (!this.context || !this.context.order || !this.context.order.payments) {
            return 0.0;
        }

        let sum = 0;
        for (const payment of this.context.order.payments) {
            sum += payment.value;
        }
        return sum;
    }

    private getWorksSum(): number {
        if (!this.context || !this.context.order || !this.context.order.works) {
            return 0.0;
        }

        let sum = 0.0;
        for (const work of this.context.order.works) {
            sum += work.price * work.quantity;
        }
        return sum;
    }

    private getMaterailsSum(): number {
        if (!this.context || !this.context.order || !this.context.order.materials) {
            return 0.0;
        }

        let sum = 0.0;
        for (const material of this.context.order.materials) {
            sum += material.price * material.quantity;
        }
        return sum;
    }

    public get controls(): Control[] {
        return [
            this.cbStage,
            this.tbActualPrice,
            this.dbDiscount,
            this.tbPaidSum,
            this.tbTotalToPay,
            this.cbAccount,
            this.cbsPrint,
            this.pnlFooter,
        ];
    }

    private async changeDocuments(sender: any, e: CheckBoxSelectChangedEventArgs<ITemplateDocument>): Promise<void> {
        if (e.items.length === 0) {
            this.cbsPrint.label = "не печатать документы";
            return;
        }

        const form = StringUtils.decline(e.items.length, ["документ", "документа", "документов"]);
        this.cbsPrint.label = `напечать ${e.items.length} ${form}`;
    }

    private async changeDiscount(sender: any, e: ValueChangedEventArgs<IDiscount>): Promise<void> {
        // let discount = e.value;

        // if (isNaN(discount.value)) {
        //     discount.value = 0.0;
        // }

        // discount[0] = discount[0].replace(",", ".");
        // this.discount[0] = parseFloat(discount[0]);
        // this.discount[1] = discount[1];

        // if (isNaN(this.discount[0])) {
        //     this.discount[0] = 0.0;
        // }

        this.discount = e.value;

        // TODO: брать локаль из филиала
        const locale = Locale.RU;

        this.calculateTotal();
        this.tbTotalToPay.text = Localizer.money(this.total, locale);
    }

    private async changeTotalPay(sender: any, e: TextChangedEventArgs): Promise<void> {
        let total = e.text;

        if (total.length === 0) {
            total = "0.0";
        }

        total = total.replace(",", ".");
        this.total = parseFloat(total);

        if (isNaN(this.total)) {
            this.total = 0.0;
        }

        // TODO: брать локаль из филиала
        const locale = Locale.RU;

        this.calculateActualPrice();
        this.tbActualPrice.text = Localizer.money(this.actualPrice, locale);
    }

    private calculateTotal(): void {
        const discountTotal = this.getDiscountTotal();
        this.total = this.actualPrice - discountTotal - this.paymentsSum;
    }

    private calculateActualPrice(): void {
        const discountTotal = this.getDiscountTotal();
        this.actualPrice = this.total + discountTotal + this.paymentsSum;
    }

    private getDiscountTotal(): number {
        const discount = this.discount.value;
        if (this.discount.type === DiscountType.Percent) {
            return (this.actualPrice * discount) / 100;
        } else if (this.discount.type === DiscountType.Fixed) {
            return discount;
        }
        return 0.0;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide();
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.onComplete || !this.cbStage.selectedItem || !this.cbAccount.selectedItem) {
            // throw new Error("Этап не задан.");
            return;
        }

        const infoDto: IOrderInfoChangeDto | undefined =
            this.discount.value > 0
                ? {
                      discount: this.discount,
                  }
                : undefined;

        const paymentDto: IOrderCreatePaymentDto | undefined =
            this.total > 0
                ? {
                      account: this.cbAccount.selectedItem.id,
                      value: parseFloat(this.total.toFixed(2)),
                      description: "Оплата заявки",
                  }
                : undefined;

        const documents = this.cbsPrint.selectedItems;

        const result = await this.onComplete(
            this.context.order,
            this.cbStage.selectedItem,
            infoDto,
            paymentDto,
            documents,
        );
        if (result) {
            this.hide(result);
        }
    }
}
