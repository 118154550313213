import { Control } from "@core/components/alt-ui/controls";

export { default } from "./alt-card-list.vue";

/** Виджет карточки. */
export interface ICardWidget<T = any> {
    id: string;
    field?: string;
    name: string;
    sortable?: boolean;
    getText?: (item: T) => string;
    getControl?: (item: T) => Control;
}

/** Поле сортировки карточек. */
export interface ICardSortField {
    id: string;
    text: string;
    direction?: "asc" | "desc";
}

/** Элемент меню карточки. */
export interface ICardMoreItem<T = any> {
    id: string;
    icon: string;
    text: string;
    variant?: string;
    click?: (item: T) => void | Promise<void>;
}

/** Функция формирования меню карточки. */
export type CardMoreFunc<T = any> = (item: T) => ICardMoreItem<T>[];

/** Настройки списка карточек. */
export interface ICardListOptions {
    /** Доступна ли панель инструментов. */
    toolbox?: boolean;

    /** Все доступные виджеты. */
    toolboxWidgets?: ICardWidget[];

    /** Доступно ли поле поиска. */
    search?: boolean;

    /** Доступна ли сортировка. */
    sort?: boolean;

    /** Доступны ли страницы. */
    pagination?: boolean;

    /** Значение количества элементов на странице. */
    limit?: number;
}

/** Объект для управления карточками из кода. */
export class CardListApi {
    /** Изменить виджет заголовка. */
    public changeHeader(header: ICardWidget | null): void {
        this.notifyChangeHeaderListeners(header);
    }

    private changeHeaderListeners = new Set<Function>();
    private notifyChangeHeaderListeners(header: ICardWidget | null): void {
        for (const listener of this.changeHeaderListeners) {
            listener(header);
        }
    }
    public addChangeHeaderListener(listener: Function): void {
        this.changeHeaderListeners.add(listener);
    }

    /** Изменить виджет подвала. */
    public changeFooter(footer: ICardWidget | null): void {
        this.notifyChangeFooterListeners(footer);
    }

    private changeFooterListeners = new Set<Function>();
    private notifyChangeFooterListeners(footer: ICardWidget | null): void {
        for (const listener of this.changeFooterListeners) {
            listener(footer);
        }
    }
    public addChangeFooterListener(listener: Function): void {
        this.changeFooterListeners.add(listener);
    }

    /** Изменить виджеты содержимого. */
    public changeWidgets(widgets: ICardWidget[] | null): void {
        this.notifyChangeWidgetsListeners(widgets);
    }

    private changeWidgetsListeners = new Set<Function>();
    private notifyChangeWidgetsListeners(widgets: ICardWidget[] | null): void {
        for (const listener of this.changeWidgetsListeners) {
            listener(widgets);
        }
    }
    public addChangeWidgetsListener(listener: Function): void {
        this.changeWidgetsListeners.add(listener);
    }

    /** Изменить лимит элементов на странице. */
    public changeLimit(limit: number): void {
        this.notifyChangeLimitListeners(limit);
    }

    private changeLimitListeners = new Set<Function>();
    private notifyChangeLimitListeners(limit: number): void {
        for (const listener of this.changeLimitListeners) {
            listener(limit);
        }
    }
    public addChangeLimitListener(listener: Function): void {
        this.changeLimitListeners.add(listener);
    }

    /** Искать строку. */
    public search(text: string): void {
        this.notifySearchListeners(text);
    }

    private searchListeners = new Set<Function>();
    private notifySearchListeners(text: string): void {
        for (const listener of this.searchListeners) {
            listener(text);
        }
    }
    public addSearchListener(listener: Function): void {
        this.searchListeners.add(listener);
    }
}
