import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import VueSelect from "vue-select";
import { IOrder, IClient, ITemplateNotification, IOrderType, ICompany, IProductType, IOffice } from "@lib";
import { IPluginBinding, IPluginNotificationSendDto } from "@lib";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Printer } from "@/@core/usecases/template/printer";
import { OrderViewNotificationModal } from "./order-view-notification.modal";
import { OrderPrintContext } from "@/@core/usecases/template/macro-replacers/order-document.macro-replacer";

@Component({
    name: "order-view-notification",
    components: {
        BButton,
        VueSelect,
        ModalComponent,
    },
})
export default class OrderViewNotification extends Vue {
    @Prop({ required: true })
    private order!: IOrder;

    @Prop({ required: true })
    private orderType!: IOrderType;

    @Prop({ default: () => [] })
    private productTypes!: IProductType[];

    @Prop({ type: Array, required: true })
    private templates!: ITemplateNotification[];

    @Prop({ type: Array, default: () => [] })
    private plugins!: IPluginBinding[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private company!: ICompany;

    private templateSelected: ITemplateNotification | null = null;

    private notificationModal: OrderViewNotificationModal;

    constructor() {
        super();

        this.notificationModal = new OrderViewNotificationModal();
        this.notificationModal.onSend = this.send.bind(this);
    }

    @Watch("order", { immediate: true, deep: true })
    private onOrderChanged(): void {
        this.initValues();
    }

    public async mounted(): Promise<void> {
        try {
            this.company = await this.$info.getCompany();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private initValues(): void {
        this.templateSelected = null;
    }

    private async showPreviewModal(): Promise<void> {
        if (this.disabled || !this.order.officeRef) {
            return;
        }

        if (this.plugins.length === 0) {
            this.$alt.toast.error("Интеграция с сервисом уведомлений отсутствует.");
            return;
        }

        const context: OrderPrintContext = {
            company: this.company,
            office: this.order.officeRef,
            order: this.order,
            orderType: this.orderType,
            productTypes: this.productTypes,
        };

        const template = this.templateSelected
            ? Printer.replaceOrderDocumentsGeneralMacros(this.templateSelected.template, context)
            : "";

        const modalContext = {
            plugins: this.plugins,
            client: this.order.clientRef,
            message: template,
        };
        await this.notificationModal.show(modalContext);
    }

    private async send(bindingId: string, dto: IPluginNotificationSendDto): Promise<boolean> {
        try {
            if (this.plugins.length === 0) {
                this.$alt.toast.error("Интеграция с сервисом уведомлений отсутствует.");
                return false;
            }

            this.$alt.loader.show();

            const result = await this.$alt.system.usecase
                .createOrderUseCase()
                .sendNotification(this.order.company as string, this.order.id, bindingId, dto);

            if (result.result.cost < 0) {
                this.$alt.toast.error("Не удалось отправить сообщение.");
                return false;
            }

            if (result.order) {
                this.$emit("order-changed", result.order);
            }

            this.$info.setBalance(result.result.balance);
            this.$alt.toast.success(`Сообщение отправлено. Цена: ${result.result.cost}`);
            return true;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось отправить сообщение:\n${e.message}`);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }
}
