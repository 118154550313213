import { Vue, Component, Prop } from "vue-property-decorator";
import {
    BButton,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BPagination,
    VBTooltip,
} from "bootstrap-vue";
import { ITableSort } from "@lib";
import { SidebarComponent } from "@/@core/components/alt-ui/sidebar/sidebar.component";
import { AltCardListToolbox } from "./alt-card-list-toolbox";
import { CardListApi, CardMoreFunc, ICardListOptions, ICardSortField, ICardWidget } from ".";
import { DelayedOperation } from "@/utils/delayed-operation";

@Component({
    name: "alt-card-list",
    components: {
        BButton,
        BCardGroup,
        BCard,
        BCardText,
        BCardTitle,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BPagination,
        ControlComponent: () => import("@core/components/alt-ui/controls/control.component"),
        SidebarComponent,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class AltCardList extends Vue {
    @Prop({ type: Array, required: true })
    private items!: any[];

    @Prop({ type: Number, required: false })
    private itemsTotal?: number;

    @Prop({ required: false })
    private header?: ICardWidget;

    @Prop({ required: false })
    private footer?: ICardWidget;

    @Prop({ required: false })
    private widgets?: ICardWidget[];

    @Prop({ required: false })
    private moreFunc?: CardMoreFunc;

    @Prop({ required: false })
    private sortFields?: ITableSort[];

    @Prop({ required: false })
    private options?: ICardListOptions;

    @Prop({ type: Object, default: () => new CardListApi() })
    private api!: CardListApi;

    private static readonly DefaultSearchDelay = 750;
    private static readonly DefaultLimit = 10;

    private toolboxSidebar: AltCardListToolbox;

    public headerCurrent?: ICardWidget = this.header;
    public footerCurrent?: ICardWidget = this.footer;
    public widgetsCurrent?: ICardWidget[] = this.widgets;

    private searchString = "";
    private limitOptions = [10, 25, 50, 100];
    private page = 1;

    private doubleClickData = {
        delay: 500,
        clicks: 0,
        timer: -1,
    };

    public constructor() {
        super();

        this.toolboxSidebar = new AltCardListToolbox();
    }

    private get total(): number {
        return this.itemsTotal ?? this.items.length;
    }

    private get limit(): number {
        return this.options?.limit ?? AltCardList.DefaultLimit;
    }

    private get offset(): number {
        return (this.page - 1) * this.limit;
    }

    private get pagesTotal(): number {
        return Math.ceil(this.total / this.limit);
    }

    public mounted(): void {
        this.initApi();
    }

    private initApi(): void {
        if (!this.api) {
            return;
        }

        this.api.addChangeHeaderListener((header: ICardWidget | null) => {
            this.headerCurrent = header ?? undefined;
        });

        this.api.addChangeFooterListener((footer: ICardWidget | null) => {
            this.footerCurrent = footer ?? undefined;
        });

        this.api.addChangeWidgetsListener((widgets: ICardWidget[] | null) => {
            this.widgetsCurrent = widgets ?? undefined;
        });

        if (this.options?.limit) {
            this.api.addChangeLimitListener((limit: number) => {
                this.changeLimit(limit);
            });
        }

        if (this.options?.search) {
            this.api.addSearchListener((text: string) => {
                this.searchString = text;
                this.startSearchNoDelay(text);
            });
        }
    }

    private async showToolbox(): Promise<void> {
        const settings = await this.toolboxSidebar.show({
            header: this.header,
            footer: this.footer,
            widgets: this.widgets,
            widgetsAll: this.options?.toolboxWidgets ?? [],
            api: this.api,
        });

        if (settings) {
            this.$emit("change-settings", settings);
        }
    }

    private startSearch(search: string): void {
        DelayedOperation.invoke("search", AltCardList.DefaultSearchDelay, () => {
            this.startSearchNoDelay(search);
        });
    }

    private startSearchNoDelay(search: string): void {
        //this.gridApi.deselectAll();
        this.page = 1;
        this.$emit("search", search);
    }

    private changeSort(field: ICardSortField): void {
        // const sortState = event.api.getSortModel();

        // // если сортировка не поменялась, событие не генерируем
        // // это обход зацикливания при изменении сортировки программно
        // if (JSON.stringify(sortState) === JSON.stringify(this.columnsSort)) {
        //     return;
        // }

        //this.gridApi.deselectAll();

        let sortState: ITableSort[] = [];

        if (field.direction === "asc") {
            sortState = [
                {
                    colId: field.id,
                    sort: "desc",
                },
            ];
        } else if (field.direction === "desc") {
            sortState = [];
        } else {
            sortState = [
                {
                    colId: field.id,
                    sort: "asc",
                },
            ];
        }

        this.$emit("sort", sortState);
    }

    private changeLimit(limit: number): void {
        this.$emit("change-limit", limit);
    }

    private changePage(page: number): void {
        if (page === this.page) {
            return;
        }

        // не генерировать событие, если нет данных
        if (0 === this.items.length) {
            return;
        }

        //this.gridApi.deselectAll();
        this.page = page;
        this.$emit("change-page", this.page, this.offset, this.limit);
    }

    private click(item: any): void {
        this.doubleClickData.clicks++;
        if (this.doubleClickData.clicks === 1) {
            this.doubleClickData.timer = setTimeout(() => {
                this.doubleClickData.clicks = 0;
                this.$emit("click", item);
                // TODO: события всплывают
                //console.log("click");
            }, this.doubleClickData.delay);
        } else {
            clearTimeout(this.doubleClickData.timer);
            this.doubleClickData.clicks = 0;
            this.$emit("dblclick", item);
            //console.log("dblclick");
        }
    }
}
