import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import AltTable, { IAltTableActionsOptions, TableApi } from "@/core/components/alt-table";
import AltCardList, { CardListApi, CardMoreFunc, ICardWidget } from "@/core/components/alt-card-list";
import { ITableCardSettings, ITableSort, TableView } from "@lib";
import { IAltTableToolboxExport } from "@/core/components/alt-table/alt-table-toolbox";
import { SidebarComponent } from "@core/components/alt-ui/sidebar/sidebar.component";
import { IAltUniversalTableToolboxContext, AltUniversalTableToolbox } from "./alt-universal-table-toolbox";
import { BButton, VBTooltip } from "bootstrap-vue";

export interface IAltUniversalTableOptions {
    /** Доступна ли панель инструментов. */
    toolbox?: boolean;

    /** Доступно ли поле поиска. */
    search?: boolean;

    /** Доступна ли сортировка. */
    sort?: boolean;

    /** Доступны ли страницы. */
    pagination?: boolean;

    // Настройки для таблицы

    /** Доступны ли ограничения для страниц таблицы. */
    tableLimits?: boolean;

    /** Доступны ли действия со строками таблицы. */
    tableActions?: boolean;

    /** Настройки экспорта данных таблицы. */
    tableExport?: IAltTableToolboxExport;

    /** Настройки действий со строками таблицы. */
    tableActionsOptions?: IAltTableActionsOptions;

    rowDoubleClick?: (item: any) => void;
}

@Component({
    name: "alt-universal-table",
    components: {
        AltTable,
        AltCardList,
        BButton,
        SidebarComponent,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class AltUniversalTable extends Vue {
    @Prop({ type: String, required: true })
    private view!: TableView;

    /** Элементы. */
    @Prop({ type: Array, required: true })
    private items!: any[];

    /** Общее количество элементов. */
    @Prop({ type: Number, required: false })
    private itemsTotal!: number | undefined;

    /** Значение количества элементов на странице. */
    @Prop({ type: Number, default: 10 })
    private itemsLimit!: number;

    /** Все доступные виджеты. */
    @Prop({ required: false })
    private cardToolboxWidgets?: ICardWidget[];

    @Prop({ required: true })
    private options!: IAltUniversalTableOptions;

    // Пропсы для таблицы

    @Prop({ type: Array, required: true })
    private tableColumns!: any[];

    @Prop({ type: Array, default: () => [] })
    private tableColumnsSort!: any[];

    @PropSync("table-update", { type: Boolean, default: false })
    private tableUpdateSync!: boolean;

    @Prop({ type: Object, default: () => {} })
    private tableCellRenderers!: object;

    // Пропсы для карточек

    @Prop({ required: false })
    private cardHeader?: ICardWidget;

    @Prop({ required: false })
    private cardFooter?: ICardWidget;

    @Prop({ required: false })
    private cardWidgets?: ICardWidget[];

    @Prop({ required: false })
    private cardMoreFunc?: CardMoreFunc;

    @Prop({ required: false })
    private cardSortFields?: ITableSort[];

    //

    private viewCurrent = this.view;

    // TODO: сделать обновление через TableApi
    private tableObj = {
        forceUpdate: false,
    };

    private toolboxSidebar = new AltUniversalTableToolbox();
    private tableApi = new TableApi();
    private cardsApi = new CardListApi();

    private get sidebarContext(): IAltUniversalTableToolboxContext {
        return {
            mobile: this.$info.ui.isMobileView,
            view: this.view,
            columns: this.tableColumns,
            export: this.options?.tableExport,
            tableApi: this.tableApi,
            header: this.cardHeader,
            footer: this.cardFooter,
            widgets: this.cardWidgets,
            widgetsAll: this.cardToolboxWidgets ?? [],
            cardsApi: this.cardsApi,
            changeTableView: view => {
                this.viewCurrent = view;
            },
            saveSettings: settings => {
                this.tableApi.gridOptions?.api?.deselectAll();
                this.$emit("save-settings", settings);
            },
        };
    }

    @Watch("view")
    private onViewChanged(viewNew: TableView): void {
        if (this.viewCurrent !== viewNew) {
            this.viewCurrent = viewNew;
        }
    }

    @Watch("tableUpdate")
    private onTableUpdateChanged(forceUpdate: boolean): void {
        if (forceUpdate) {
            this.tableObj.forceUpdate = true;
            this.tableUpdateSync = false;
        }
    }

    private changePage(page: number, offset: number, limit: number): void {
        this.$emit("change-page", page, offset, limit);
    }

    private changeLimit(limit: number, pageCurrent?: number): void {
        this.$emit("change-limit", limit, pageCurrent);
    }

    private changeColumns(columnsDef: any[]): void {
        this.$emit("change-columns", columnsDef);
    }

    private sort(sortState: any): void {
        this.$emit("sort", sortState);
    }

    private search(searchString: string): void {
        this.$emit("search", searchString);
    }

    private changeCardSettings(settings: ITableCardSettings): void {
        this.$emit("change-card-settings", settings);
    }

    private onDblClick(item: any): void {
        this.$emit("dblclick", item);
    }

    public async showToolbox(): Promise<void> {
        await this.toolboxSidebar.show(this.sidebarContext);
    }
}
