var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.viewCurrent === 'cards')?_c('alt-card-list',{attrs:{"items":_vm.items,"items-total":_vm.itemsTotal,"header":_vm.cardHeader,"footer":_vm.cardFooter,"widgets":_vm.cardWidgets,"more-func":_vm.cardMoreFunc,"sort-fields":_vm.cardSortFields,"options":{
            toolbox: _vm.options.toolbox,
            toolboxWidgets: _vm.cardToolboxWidgets,
            search: _vm.options.search,
            sort: _vm.options.sort,
            pagination: _vm.options.pagination,
            limit: _vm.itemsLimit,
        },"api":_vm.cardsApi},on:{"change-settings":_vm.changeCardSettings,"change-page":_vm.changePage,"change-limit":_vm.changeLimit,"sort":_vm.sort,"search":_vm.search,"dblclick":_vm.onDblClick},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_vm._t("header-left")]},proxy:true},{key:"settings",fn:function(){return [(_vm.options && _vm.options.toolbox)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Настройки карточек'),expression:"'Настройки карточек'",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-auto mr-0.5 md:block p-0.5",attrs:{"variant":"flat-secondary"},on:{"click":_vm.showToolbox}},[_c('feather-icon',{attrs:{"icon":"SettingsIcon"}})],1):_vm._e()]},proxy:true}],null,true)}):(_vm.viewCurrent === 'table')?_c('alt-table',{attrs:{"items":_vm.items,"items-total":_vm.itemsTotal,"items-limit":_vm.itemsLimit,"pagination":_vm.options.pagination,"update":_vm.tableObj.forceUpdate,"columns":_vm.tableColumns,"columns-sort":_vm.tableColumnsSort,"limits":_vm.options.tableLimits,"search":_vm.options.search,"options":{
            toolbox: _vm.options.toolbox,
            export: _vm.options.tableExport,
            doubleClick: _vm.options.rowDoubleClick,
        },"actions":_vm.options.tableActions,"actions-options":_vm.options.tableActionsOptions,"cell-renderers":_vm.tableCellRenderers,"table-api":_vm.tableApi},on:{"update:update":function($event){return _vm.$set(_vm.tableObj, "forceUpdate", $event)},"page-changed":_vm.changePage,"limit-changed":_vm.changeLimit,"columns-changed":_vm.changeColumns,"sort":_vm.sort,"search":_vm.search,"dblclick":_vm.onDblClick},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_vm._t("header-left")]},proxy:true},{key:"settings",fn:function(){return [(_vm.options && _vm.options.toolbox)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Настройки таблицы'),expression:"'Настройки таблицы'",modifiers:{"hover":true,"bottom":true}}],staticClass:"ml-auto mr-0.5 md:block p-0.5",attrs:{"variant":"flat-secondary"},on:{"click":_vm.showToolbox}},[_c('feather-icon',{attrs:{"icon":"SettingsIcon"}})],1):_vm._e()]},proxy:true}],null,true)}):_vm._e(),(_vm.options && _vm.options.toolbox)?_c('sidebar-component',{attrs:{"handler":_vm.toolboxSidebar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }